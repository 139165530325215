import { ClarificationDescriptionHandler } from './ClarificationDescriptionHandler';
import { DefaultHandler } from './DefaultHandler';
import { ErrorHandler } from './ErrorHandler';
import { GollandHandler } from './GollandHandler';
import { OneFactorHandler } from './OneFactorHandler';
import { SituationsFriendsHandler } from './SituationsFriendsHandler';
import { OUTPUT_HANDLER } from '../../constants/OutputHandler';

const outputHandlerMap = {
  [OUTPUT_HANDLER.DEFAULT]: DefaultHandler,
  [OUTPUT_HANDLER.ERROR]: ErrorHandler,
  [OUTPUT_HANDLER.CLARIFICATION_DESCRIPTION]: ClarificationDescriptionHandler,
  [OUTPUT_HANDLER.ONE_FACTOR]: OneFactorHandler,
  [OUTPUT_HANDLER.SITUATIONS_FRIENDS]: SituationsFriendsHandler,
  [OUTPUT_HANDLER.GOLLAND]: GollandHandler,
};

export default outputHandlerMap;
