import { Avatar, Button, SimpleCell } from "@vkontakte/vkui";

import styles from './UserItem.module.css';
import { FC } from "react";
import { UserItemProps } from "./types";

export const UserItem: FC<UserItemProps> = ( { userData, onDeleteClick, visible }) => {
    if (!visible) {
        return <></>;
    }
    return (
        <div className={styles.container}>
            <SimpleCell
                hasHover={false}
                subtitle={userData.subtitle}
                before={<Avatar size={48} src={userData.photoSrc}/>}
            >
                {userData.name}
            </SimpleCell>
            <div className={styles.buttonsContainer}>
                <Button onClick={onDeleteClick} appearance='negative'>Удалить</Button>
            </div>
        </div>
    );
};
