import { Avatar, SimpleCell } from "@vkontakte/vkui";

import styles from './UserItem.module.css';
import { FC } from "react";
import { UserItemProps } from "./types";

export const UserItem: FC<UserItemProps> = ( { 
    userData,
    temperamentData,
    appearance,
    onClick
}) => {
    return (
        <div className={styles['container_' + appearance]} onClick={onClick}>
            <SimpleCell
                hasHover={false}
                hasActive={false}
                subtitle={userData.subtitle}
                before={<Avatar size={48} src={userData.photoSrc}/>}
            >
                {userData.name}
            </SimpleCell>
            <div className={styles.flexContainer}>
                <p style={{color: temperamentData.textColor}}>{temperamentData.name}</p>
                <Avatar size={48} src={temperamentData.avatar}/>
            </div>
        </div>
    );
};
