import { FC, useState } from "react";
import { UserItemContainerProps } from "./types";
import { UserItem } from "./UserItem";
import usersApi from "../../../../../../api/UsersApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserVKID } from "../../../../../../store/userSlice/userSelectors";
import { updateUser } from "../../../../../../store/userSlice";

const UserItemContianer: FC<UserItemContainerProps> = ({ userData }) => {
    const [visible, setVisible] = useState(true);
    const userId = useSelector(selectUserVKID);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const onDenyClick = () => {
        setVisible(false);
        usersApi.deleteIncomingRequest(userData.vkId, userId);
        usersApi.deleteSubmittedRequest(userId, userData.vkId);

        const social = Object.assign({}, user!.social);
        social.submittedRequestsUsersIds = social.submittedRequestsUsersIds.filter((id) => id !== userData.vkId) || [];

        dispatch(updateUser({social: social}));
    }

    return (
        <UserItem   
            userData={userData}  
            onDenyClick={onDenyClick}
            visible={visible}
        />
    );
};

export default UserItemContianer;
