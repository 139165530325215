import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TestType } from "../../types/test.type";
import { UserInfo } from "../../types/userInfo.type";

type CooperativePassingsData = {
    selectedUser: {
        id: number | undefined,
        firstName: string | undefined,
        lastName: string | undefined,
        photo: string | undefined,
    },
    isMenuOpen: boolean;
    friendsInfo: UserInfo[],
    currentCooperativeTest: TestType | undefined,
    currentResultType: string[],
}

const initialState: CooperativePassingsData = {
    selectedUser: {
            id: undefined,
            firstName: undefined,
            lastName: undefined,
            photo: undefined,
        },
    isMenuOpen: false,
    friendsInfo: [],
    currentCooperativeTest: undefined,
    currentResultType: [],
};
const jointPassageSlice = createSlice({
    name: 'cooperativePassage',
    initialState: initialState,
    reducers: {
        updateSelectedUser(state, action: PayloadAction<object>) {
            Object.assign(state.selectedUser, action.payload);
        },
        updateFriends(state, action: PayloadAction<UserInfo[]>) {
            state.friendsInfo = action.payload;
        },
        updateIsMenuOpen(state, action: PayloadAction<boolean>){
            state.isMenuOpen = action.payload;
        },
        updateCurrentResultType(state, action: PayloadAction<string[]>){
            state.currentResultType = action.payload;
        },
        setCurrentCooperativeTest(state, action: PayloadAction<TestType>){
            //@ts-ignore
            state.currentCooperativeTest = action.payload;
        }

    }
});

export const { updateSelectedUser, updateFriends, updateIsMenuOpen, updateCurrentResultType, setCurrentCooperativeTest } = jointPassageSlice.actions;

export default jointPassageSlice.reducer;
