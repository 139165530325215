import { FC } from "react";
import { MobileNavigationMenuProps } from "./types";
import { Button } from "@vkontakte/vkui";

import styles from './MobileNavigationMenu.module.css';
import { Icon28CancelCircleOutline } from "@vkontakte/icons";

export const MobileNavigationMenu: FC<MobileNavigationMenuProps> = ({
onIncomingRequestsButtonClick,
onSubmittedRequestsButtonClick,
onMainPanelButtonClick,
onAddFriendPanelButtonClick,
onFriednListPanelButtonClick,
closeMenu,
appearance
}) => {
    return (
        <div className={styles['container_' + appearance]}>
            <Button className={styles.button} onClick={onMainPanelButtonClick}>Главная</Button>
            <Button className={styles.button} onClick={onFriednListPanelButtonClick}>Друзья</Button>
            <Button className={styles.button} onClick={onAddFriendPanelButtonClick}>Добавить в друзья</Button>
            <Button className={styles.button} onClick={onIncomingRequestsButtonClick}>Входящие</Button>
            <Button className={styles.button} onClick={onSubmittedRequestsButtonClick}>Отправленные</Button>
            <Icon28CancelCircleOutline onClick={closeMenu} width={70} height={70}/>
        </div>
    );
};
