export const temperamentData = {
    Меланхолик: {
        name: 'Меланхолик',
        avatar: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychotest-static-images/Melancholic.png',
        textColor: '#4673c8',
    },
    Флегматик: {
        name: 'Флегматик',
        avatar: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychotest-static-images/Phlegmatic.png',
        textColor: '#74cd97',
    },
    Сангвиник: {
        name: 'Сангвиник',
        avatar: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychotest-static-images/Sanguine.png',
        textColor: '#dfc459',
    },
    Холерик: {
        name: 'Холерик',
        avatar: 'https://84a68b47b1344f5bba45d50d97c8c4b7.storage.msk.3hcloud.com/psychotest-static-images/Сholeric.png',
        textColor: '#cc6e7c',
    }
};
