import { FC } from "react";
import { TestPanelProps } from './types';
import { Avatar, Group, Panel, PanelHeader, PanelHeaderBack, Spinner, Title } from "@vkontakte/vkui";
import { DesktopNavigation } from "../../../../components/navigation/DesktopNavigation";
import { NAVIGATION } from "../../../../constants/Navigation";

import styles from './TestPanel.module.css';
import { NavigationMenu } from "../../components/NavigationMenu";
import { UserItem } from "./components/UserItem";
import { MobileNavigationMenu } from "../../components/MobileNavigationMenu";
import { Icon28Menu } from "@vkontakte/icons";

export const TestPanel: FC<TestPanelProps> = ({ 
    id, 
    isDesktop, 
    test, 
    friends, 
    temperamentData,
    loading,
    appearance,
    onBackClick,
    isMenuOpen,
    openMenu
}) => {
    return (
        <Panel id={id}>
            <PanelHeader onClick={onBackClick} before={<PanelHeaderBack/>}>Совместные прохождения</PanelHeader>
            <Group>
                {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}       
                <div className={isDesktop && isMenuOpen ? styles.container_open: ''}>
                    {isDesktop ? <NavigationMenu/> : isMenuOpen ? <MobileNavigationMenu/> : <Icon28Menu onClick={openMenu} width={50} height={50}/>}
                    {(isDesktop || (!isDesktop && !isMenuOpen)) &&
                        <div className={styles.panelContainer}>
                            <div className={styles.titleContainer}>
                                <Title>{test?.full_name}</Title>
                            </div>
                            <div className={styles.header}>
                                <img className={styles.img} src={test?.decorations.photo}/>
                            </div>
                            <div className={styles.userResult}>              
                                Вы - <span style={{color: temperamentData.textColor}}>{temperamentData.name}</span>
                                <Avatar size={48} src={temperamentData.avatar}/>
                            </div>
                            <div className={styles.titleContainer}>
                                <Title>Рeзультаты друзей</Title>
                            </div>
                            <div className={styles.friendsResultsContainer}>
                                {(loading || !friends) && <Spinner size='large'/>}
                                {!loading && friends && 
                                    friends.map((friend, index) => {
                                        return(
                                            <div className={index % 2 === 0 ? styles['backgroundEven_' + appearance] : styles['backgroundOdd_' + appearance]}>
                                                <UserItem userData={friend.friendInfo} result={friend.result}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </Group>
        </Panel>
    );
};
