import { FC } from "react";
import { ResultPanelContainerProps } from "./types";
import { ResultPanel } from "./ResultPanel";
import { useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { useRouter } from "../../../../hooks/useRouter";
import { selectCurrentResultType } from "../../../../store/cooperativePassageSlice/selectors";
import { data } from "./constants";
import { temperamentData as tData } from "../TestPanel/constants";

const ResultPanelContianer: FC<ResultPanelContainerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const router = useRouter();
    const resulType = useSelector(selectCurrentResultType);
    //@ts-ignore
    const result = data[resulType[0]][resulType[1]]  
    //@ts-ignore
    const temperamentData = [tData[resulType[0]], tData[resulType[1]]];

    const onBackClick = () => {
        router.back();
    }

    return (
        <ResultPanel
            id={id}
            isDesktop={isDesktop}
            onBackClick={onBackClick}
            result={result}
            temperamentData={temperamentData}
        />
    );
};

export default ResultPanelContianer;
