import { useSelector } from "react-redux";
import { MODAL } from "../../../../../../constants/Modal";
import { useRouter } from "../../../../../../hooks/useRouter";
import { selectAppearance } from "../../../../../../store/settingsSlice/selectors";
import { FriendList } from "./FriendList";

const FriendListContainer = () => {
    const router = useRouter();
    const appearance = useSelector(selectAppearance);

    const handleFriendButton = () => {
        router.forward({ modal: MODAL.FRIEND_SEARCH });
    }

    return (
        <FriendList handleFriendButton={handleFriendButton} appearance={appearance}/>
    );
};

export default FriendListContainer;
