import { FC, useState } from "react";
import { RequestConfirmationModal } from "./RequestConfirmationModal";
import { RequestConfirmationModalContainerProps } from "./types";
import { useRouter } from "../../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedUser } from "../../../../store/cooperativePassageSlice/selectors";
import usersApi from "../../../../api/UsersApi";
import { selectUser, selectUserVKID } from "../../../../store/userSlice/userSelectors";
import { updateUser } from "../../../../store/userSlice";

const RequestConfirmationModalContainer: FC<RequestConfirmationModalContainerProps> = ({ id }) => {
    const userInfo = useSelector(selectSelectedUser);
    const userVKID = useSelector(selectUserVKID);
    const router = useRouter();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [status, setStatus] = useState('default');

    const onCancelClick = () => {
        router.back();
    }

    const onAcceptClick = async() => {
        if (userInfo.id) {
            const hasSubmittedRequest = await usersApi.hasSubmittedRequest(userInfo.id);
            const isAlreadyFriend = await usersApi.hasFriend(userInfo.id);
            const isUserExists = await usersApi.isUserExists(userInfo.id);
            
            if (!isUserExists) {
                setStatus('userDoesntExists');
                return;
            }
            else if(isAlreadyFriend) {
                setStatus('isAlreadyFriend');
                return;
            }
            else if(userVKID === userInfo.id) {
                setStatus('selfRequest');
                return;
            }
            else if(hasSubmittedRequest){
                setStatus('hasSubmittedRequest');
                return;
            }
            else {
                usersApi.sendFriendRequest(userInfo.id);
                const newSubmittedRequestsUserIds = [...user!.social?.submittedRequestsUsersIds || [], userInfo.id];
                const social = Object.assign({}, user?.social);
                social.submittedRequestsUsersIds = newSubmittedRequestsUserIds;
                dispatch(updateUser({social: social}));
            }
        }

        router.back();
    }

    return (
        <RequestConfirmationModal 
            id={id}
            userInfo={userInfo}
            onCancelClick={onCancelClick}
            onAcceptClick={onAcceptClick}
            status={status}
        />
    ); 
};

export default RequestConfirmationModalContainer;
