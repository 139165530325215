import bridge from '@vkontakte/vk-bridge';
import { connect } from 'react-redux';

import { SituationsFriendsHandler, StoryType } from './SituationsFriendsHandler';
import eventsApi from '../../../api/EventsApi';
import { RootState } from '../../../store';
import { selectLocation } from '../../../store/routerSlice/routerSelectors';
import { selectIsDesktop } from '../../../store/settingsSlice/selectors';
import { selectTestShortName } from '../../../store/testSlice/testSelectors';
import {
  selectUserFullName,
  selectUserPhoto,
  selectUserVKID,
} from '../../../store/userSlice/userSelectors';
import { isObject } from '../../../utils/isObject';

function mapStateToProps(state: RootState) {
  const location = selectLocation(state);
  const testShortName = selectTestShortName(state);
  const vkId = selectUserVKID(state);

  const shareResultInStory = (story: StoryType) => {
    bridge
      .send('VKWebAppShowStoryBox', story.story_box)
      .then((data) => { 
        const event = story.successfulEvent;
        if (event && isObject(event) && vkId !== undefined) {
          eventsApi.sendStatistics({
            vk_id: vkId,
            ...event,
            data, 
            route: {
              ...location,
              test_short_name: testShortName,
            },
          });
        }
      })
      .catch((error) => { 
        const event = story.failedEvent;
        if (event && isObject(event) && vkId !== undefined) {
          eventsApi.sendStatistics({
            vk_id: vkId,
            ...event,
            data: error, 
            route: {
              ...location,
              test_short_name: testShortName,
            },
          });
        } else {
          console.log(error.error_data.error_reason);
        }
      });
  };

  return {
    shareResultInStory,
    userVKID: selectUserVKID(state),
    userPhoto: selectUserPhoto(state),
    userFullName: selectUserFullName(state),
    isDesktop: selectIsDesktop(state),
  };
}

export default connect(mapStateToProps)(SituationsFriendsHandler);
