import { FC} from "react";
import { CooperativePassageView } from "./CooperativePassageView";
import { CooperativePassageViewContainerProps } from "./types";
import { selectPanel } from "../../store/routerSlice/routerSelectors";
import { useSelector } from "react-redux";

const CooperativePassageViewContainer: FC<CooperativePassageViewContainerProps> = ({ id }) => {
    const activePanel = useSelector(selectPanel);

    return (<CooperativePassageView id={id} activePanel={activePanel}/>);
};

export default CooperativePassageViewContainer;
