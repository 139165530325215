import { FC } from "react";
import { NotPassedModal } from "./NotPassedModal";
import { NotPassedModalContainerProps } from "./types";
import { useSelector } from "react-redux";
import { selectCurrentCooperativeTest } from "../../../../store/cooperativePassageSlice/selectors";

const NotPassedModalContainer: FC<NotPassedModalContainerProps> = ({ id }) => {
    const test = useSelector(selectCurrentCooperativeTest);

    return (
        <NotPassedModal 
            id={id}
            test={test!}
        />
    );
};

export default NotPassedModalContainer;
