import { Button } from "@vkontakte/vkui";
import { FriendListProps } from "./types";
import { FC } from "react";

import styles from './FriendList.module.css';

export const FriendList: FC<FriendListProps> = ({ handleFriendButton, appearance }) => {
    return (
        <div className={styles['container_' + appearance]}>
            <p>Или выберите среди друзей во вконтакте.</p>
            <div className={styles.buttonContainer}>
                <Button 
                    stretched
                    className={styles.button}
                    onClick={handleFriendButton}
                >
                    Выбрать из друзей
                </Button>
            </div>
        </div>
    );
};
