import { Avatar, Group, Panel, PanelHeader, PanelHeaderBack, Title } from "@vkontakte/vkui"
import { ResultPanelProps } from "./types";
import { FC } from "react";
import { DesktopNavigation } from "../../../../components/navigation/DesktopNavigation";
import { NAVIGATION } from "../../../../constants/Navigation";

import styles from './ResultPanel.module.css';
import { Icon28AddOutline } from "@vkontakte/icons";

export const ResultPanel: FC<ResultPanelProps> = ({ 
    id,
    isDesktop,
    onBackClick,
    result,
    temperamentData,
 }) => {
    return (
        <Panel id={id}>
            <PanelHeader onClick={onBackClick} before={<PanelHeaderBack/>}>Совместные прохождения: добавить друга</PanelHeader>
            <Group>
                {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}         
                <div className={styles.panelContainer}>
                    <div className={isDesktop ? styles.resultContainer_desktop :  styles.resultContainer_mobile}>
                        <div className={styles.flexContainer}>
                            <Avatar size={48} src={temperamentData[0].avatar}/>
                            <p style={{color: temperamentData[0].textColor}}>{temperamentData[0].name}</p>
                        </div>
                        <div className={styles.flexContainer}>
                            <Icon28AddOutline/>
                        </div>
                        <div className={styles.flexContainer}>
                            <p style={{color: temperamentData[1].textColor}}>{temperamentData[1].name}</p>
                            <Avatar size={48} src={temperamentData[1].avatar}/>
                        </div>
                    </div>
                    <p className={styles.description}>{result.description}</p>
                    <div className={styles.textBlock}>
                        <div className={styles.titleContainer}>
                            <Title>Положительные стороны взаимоотношений</Title>
                        </div>
                        {
                            result.advantages.map((advantage: any) => {
                                return (
                                    <div>
                                        <Title level='2'>{advantage.name}:</Title>
                                        {
                                            advantage.description.map((text: any) => {
                                                return <p className={styles.text}>- {text}</p>
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className={styles.textBlock}>
                        <div className={styles.titleContainer}>
                            <Title>Возможные проблемы</Title>
                        </div>
                        {
                        result.disadvantages.map((disadvantage: any) => {
                                return (
                                    <div>
                                        <Title level='2'>{disadvantage.name}:</Title>
                                        {
                                            disadvantage.description.map((text: any) => {
                                                return <p className={styles.text}>- {text}</p>
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                    <p className={styles.description}>{result.conclusion}</p>
                </div>            
            </Group>
        </Panel>
    );
};