import { useDispatch } from "react-redux";
import { PANEL } from "../../../../constants/Panel";
import { useRouter } from "../../../../hooks/useRouter";
import { MobileNavigationMenu } from "./MobileNavigationMenu";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";
import { useAppearance } from "@vkontakte/vkui";

const MobileNavigationMenuContainer = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const appearance = useAppearance();

    const onMainPanelButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_MAIN});
        closeMenu();
    };

    const onFriednListPanelButtonClick = () => {
         router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_FRIEND_LIST });
         closeMenu();
    }

    const onAddFriendPanelButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_ADD_FRIEND});
        closeMenu();
    };

    const onIncomingRequestsButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_INCOMING_REQUESTS });
        closeMenu();
    };

    const onSubmittedRequestsButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_SUBMITTED_REQUESTS });
        closeMenu();
    }

    const closeMenu = () => {
        dispatch(updateIsMenuOpen(false));
    }

    return (
        <MobileNavigationMenu
            onMainPanelButtonClick={onMainPanelButtonClick}
            onFriednListPanelButtonClick={onFriednListPanelButtonClick}
            onAddFriendPanelButtonClick={onAddFriendPanelButtonClick}
            onIncomingRequestsButtonClick={onIncomingRequestsButtonClick}
            onSubmittedRequestsButtonClick={onSubmittedRequestsButtonClick}
            closeMenu={closeMenu}
            appearance={appearance}
        />
    );
};

export default MobileNavigationMenuContainer;
