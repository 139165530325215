import { FC, useEffect, useState } from "react";
import { FriendListPanelContainerProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { FriendListPanel } from "./FriendListPanel";
import { selectUserAccessToken } from "../../../../store/userSlice/userSelectors";
import { useSetAccessToken } from "../../../../hooks/useSetAccessToken";
import { getUsersCommonData } from "../../../../api/VKWebApi";
import { UserInfo } from "../../../../types/userInfo.type";
import { useAppearance } from "@vkontakte/vkui";
import { useRouter } from "../../../../hooks/useRouter";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";
import { selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import usersApi from "../../../../api/UsersApi";

const FriendListPanelContainer: FC<FriendListPanelContainerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const accessToken = useSelector(selectUserAccessToken);
    const setAccessToken = useSetAccessToken('', ['friends']);
    const appearance = useAppearance();
    const router = useRouter();
    const isMenuOpen = useSelector(selectIsMenuOpen);
    const dispatch = useDispatch()

    const [friendsData, setFriendsData] = useState<UserInfo[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getFriendsData = async() => {
            if (accessToken) {
                const friendsIds = await usersApi.getUserFriends();
                const friendsData = await getUsersCommonData(friendsIds, accessToken);
                setFriendsData(friendsData);
                setLoading(false);
            }
            else {
                setAccessToken();
            }
        }

        getFriendsData();
    }, [accessToken]);

    const onBackClick = () => {
        router.back();
    }

     const openMenu = () => {
        dispatch(updateIsMenuOpen(true));
    }

    return (
        <FriendListPanel
            id={id}
            isDesktop={isDesktop}
            friendsData={friendsData}
            loading={loading}
            appearance={appearance}
            onBackClick={onBackClick}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
        />
    );
};

export default FriendListPanelContainer;
