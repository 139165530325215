import { Avatar, Button, SimpleCell } from "@vkontakte/vkui";

import styles from './UserItem.module.css';
import { FC } from "react";
import { UserItemProps } from "./types";

export const UserItem: FC<UserItemProps> = ( { userData, onAcceptClick, onDenyClick, visible }) => {
    if (!visible) {
        return <></>;
    }
    return (
        <div className={styles.container}>
            <SimpleCell
                subtitle={userData.subtitle}
                before={<Avatar size={48} src={userData.photoSrc}/>}
            >
                {userData.name}
            </SimpleCell>
            <div className={styles.buttonsContainer}>
                <Button onClick={onAcceptClick} appearance='positive'>Принять</Button>
                <Button onClick={onDenyClick} appearance='negative'>Отклонить</Button>
            </div>
        </div>
    );
};
