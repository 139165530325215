import React from 'react';
import { connect, useSelector } from 'react-redux';

import { useRouter } from '../../../../hooks/useRouter';

import { RootState } from '../../../../store';
import { selectResult, selectResultData } from '../../../../store/resultSlice/resultSliceSelectors';

import { PanelType } from '../../../../constants/Panel';
import { ResultItemType } from '../../../../types/result.type';

import { ResultPanel } from './ResultPanel';
import { selectTestByShortName } from '../../../../store/testsSlice/testsSelectors';

import store from '../../../../store';

type ContainerPropsType = {
  id: PanelType;
  results?: ResultItemType[];
};

const ResultPanelContainer: React.FC<ContainerPropsType> = (props) => {
  const router = useRouter();
  const resultData = useSelector(selectResultData);
  const test = useSelector((state: RootState) => selectTestByShortName(store.getState() as RootState, resultData!.test_short_name));

  return (
    <ResultPanel
      onBackClick={router.back}
      test={test}
      {...props}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    results: selectResult(state),
  };
}

export default connect(mapStateToProps)(ResultPanelContainer);
