import { FC, useState } from "react";
import { UserItemContainerProps } from "./types";
import { UserItem } from "./UserItem";
import usersApi from "../../../../../../api/UsersApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserVKID } from "../../../../../../store/userSlice/userSelectors";
import { updateUser } from "../../../../../../store/userSlice";

const UserItemContianer: FC<UserItemContainerProps> = ({ userData }) => {
    const [visible, setVisible] = useState(true);
    const userId = useSelector(selectUserVKID);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const onDeleteClick = () => {
        setVisible(false);
        usersApi.deleteUserSocialFriend(userId ,userData.vkId);
        usersApi.deleteUserSocialFriend(userData.vkId, userId);
        
        const newFriendsIds = user?.social?.friendsIds.filter((id) => id !== userData.vkId) || [];
        const social = Object.assign({}, user?.social);
        social!.friendsIds = newFriendsIds;

        dispatch(updateUser({social: social}));    
    }

    return (
        <UserItem   
            userData={userData}  
            onDeleteClick={onDeleteClick}
            visible={visible}
        />
    );
};

export default UserItemContianer;
