import api from './api';

import { SettingsType } from '../types/settings.type';

export type GetTestsQueryParams = {
  testsShortName?: string[];
  withQuestions?: boolean;
  withPercent?: boolean;
};

function getUser() {
  return api('users');
}

function saveSettings(settings: Partial<SettingsType>) {
  return api('users/save_settings', settings, { method: 'PUT' });
}

function acceptPrivacyPolicy() {
  return api('users/accept_privacy_policy', {}, { method: 'PUT' });
}

function sendFriendRequest(receiver_vk_id: number) {
  return api('users/send_friend_request', {receiver_vk_id: receiver_vk_id}, {method: 'PUT'});
}

function getUserFriends() {
  return api('users/get_user_friends');
}

function getUserIncomingRequests() {
  return api ('users/get_user_incomingRequests');
}

function getUserSubmittedRequests() {
  return api('users/get_user_isubmittedRequests');
}

function addFrined(friend_vk_id: number) {
  return api('users/add_friend', { friend_vk_id: friend_vk_id }, {method: 'PUT'});
}

function deleteIncomingRequest(owner_vk_id: number | undefined, deleting_vk_id: number | undefined) {
  const data = {
    owner_vk_id: owner_vk_id,
    deleting_vk_id: deleting_vk_id,
  }
  return api('users/delete_incoming_request', data, {method: 'DELETE'});
}

function deleteSubmittedRequest(owner_vk_id: number | undefined, deleting_vk_id: number | undefined) {
  const data = {
    owner_vk_id: owner_vk_id,
    deleting_vk_id: deleting_vk_id,
  }
  return api('users/delete_submitted_request', data, {method: 'DELETE'});
}

function getUserSocialFriends() {
  return api('users/get_user_social_friends');
}

function deleteUserSocialFriend(user_vk_id: number | undefined, friend_vk_id: number | undefined){
  const data = {
    user_vk_id: user_vk_id,
    friend_vk_id: friend_vk_id,
  }
  return api('users/delete_user_social_friend', data, { method: 'DELETE' });
}

function hasSubmittedRequest(receiver_vk_id: number | undefined) {
  return api('users/has_submitted_request', { receiver_vk_id: receiver_vk_id }, { method: 'PUT' });
}

function hasFriend(friend_vk_id: number) {
  return api('users/has_friend', { friend_vk_id: friend_vk_id }, { method: 'PUT' });
}

function isUserExists(vk_id: number) {
  return api('users/is_user_exists', {vk_id: vk_id}, {method: 'PUT'});
}

function getFriendsThatPassedTest(test_short_name: string){
  return api('users/get_friends_that_passed_test', {test_short_name: test_short_name}, { method: 'PUT' });
}

const usersApi = {
  getUser,
  saveSettings,
  acceptPrivacyPolicy,
  sendFriendRequest,
  getUserFriends,
  getUserIncomingRequests,
  getUserSubmittedRequests,
  addFrined,
  deleteIncomingRequest,
  deleteSubmittedRequest,
  getUserSocialFriends,
  deleteUserSocialFriend,
  hasSubmittedRequest,
  hasFriend,
  isUserExists,
  getFriendsThatPassedTest
};

export default usersApi;
