import { FC } from "react";
import { CooperativeSuggestionProps } from "./types";

import styles from './CooperativeSuggestion.module.css';
import { Icon56Users3Outline } from "@vkontakte/icons";

export const CooperativeSuggestion: FC<CooperativeSuggestionProps> = ({ onClick, appearance }) => {
    return(
        <div className={styles['container_' + appearance]} onClick={onClick}>
            <div className={styles.textContainer}>
                <span>А вы знали, что этот тест можно пройти совместно с друзьями?</span>
                <span>Узнайте подробности во вкладке "Совместные прохождения"</span>
            </div>
            <Icon56Users3Outline />
        </div>
    );  
};
