import { FC } from "react";
import { NavigationMenuProps } from "./types";
import { Button } from "@vkontakte/vkui";

import styles from './NavigationMenu.module.css';
import { Icon28Menu } from "@vkontakte/icons";

export const NavigationMenu: FC<NavigationMenuProps> = ({
onIncomingRequestsButtonClick,
onSubmittedRequestsButtonClick,
onMainPanelButtonClick,
onAddFriendPanelButtonClick,
onFriednListPanelButtonClick,
toggleMenu,
isMenuOpen,
appearance
}) => {
    return (
        <div className={`${styles.menuContainer} ${isMenuOpen ? styles['open_' + appearance] : styles.close}`}>
            <Icon28Menu onClick={toggleMenu}/>
            {isMenuOpen &&
                <div className={styles.container}>
                    <Button onClick={onMainPanelButtonClick}>Главная</Button>
                    <Button onClick={onFriednListPanelButtonClick}>Друзья</Button>
                    <Button onClick={onAddFriendPanelButtonClick}>Добавить в друзья</Button>
                    <Button onClick={onIncomingRequestsButtonClick}>Входящие</Button>
                    <Button onClick={onSubmittedRequestsButtonClick}>Отправленные</Button>
                </div>
            }          
        </div>
    );
};
