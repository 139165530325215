export const MODAL = {
  INSTRUCTION: 'instruction',
  ABOUT_US: 'aboutUs',
  SHARE_GOLLAND_RESULT: 'shareGollandResult',
  ABOUT_GOLLAND: 'aboutGolland',
  REQUEST_CONFIRMATION: 'requestConfirmation',
  FRIEND_SEARCH: 'friendSearch',
  NOT_PASSED: 'notPassed',
} as const;

export type ModalType = typeof MODAL[keyof typeof MODAL];
