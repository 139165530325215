import { FC, useState } from "react";
import { CollaborativeRequestFormProps } from "./types";
import styles from './CollaborativeRequestForm.module.css';
import { Button, Input } from "@vkontakte/vkui";

export const CollaborativeRequestForm: FC<CollaborativeRequestFormProps> = ({ handleRequestButton, appearance}) => {
    const [linkToProfile, setLinkToProfile] = useState<string>('');

    const onInputChange = (value: string) => {
        setLinkToProfile(value);
    };

    return (
        <div className={styles['container_' + appearance] }>
            <p>Или введите ссылка на страницу пользователя во вконтакте.</p>
            <div className={styles.enteringContainer}>                
                <Input onChange={(e) => onInputChange(e.target.value)} placeholder="Введите ссылку на страницу во вконтакте" type="text"/>
                <div className={styles.buttonContainer}>
                    <Button className={styles.button} onClick={() => handleRequestButton(linkToProfile)}>Отправить приглашение</Button>
                </div>
            </div>          
        </div>
    );
};
