import { ModalPage, Title } from "@vkontakte/vkui";
import { NotPassedModalProps } from "./types";
import { FC } from "react";
import styles from './NotPassedModal.module.css';
import { TestCard } from "../../../../components/test/TestCard";

export const NotPassedModal: FC<NotPassedModalProps> = ({ 
id,
test
}) => {
    return (
        <ModalPage id={id}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <Title>У вас нет доступа</Title>
                </div>
                <p>Чтобы увидеть результаты ваших друзей и ваши общие результаты, необходимо сначала пройти этот тест.</p>
                <p>Вы можете пройти тест ниже:</p>
                <div className={styles.testContainer}>
                    <TestCard test={test}/>
                </div>
            </div>
        </ModalPage>
    );
};
