import { VIEW, ViewType } from './View';
import { PANEL, PanelType } from './Panel';
import { MODAL, ModalType } from './Modal';

type Structure = {
    DEFAULT_VIEW: ViewType;
    VIEWS: Record<ViewType, {
        DEFAULT_PANEL: PanelType;
        PANELS: PanelType[];
        DEFAULT_MODAL: ModalType | null;
        MODALS: ModalType[];
    }>;
};

export const STRUCTURE: Structure = {
    DEFAULT_VIEW: VIEW.TESTS,
    VIEWS: {
        [VIEW.TESTS]: {
            DEFAULT_PANEL: PANEL.TESTS,
            PANELS: [PANEL.TESTS, PANEL.CURRENT_TEST, PANEL.ADDING_TEST_FORM],
            DEFAULT_MODAL: null,
            MODALS: [MODAL.INSTRUCTION],
        },
        [VIEW.PROFILE]: {
            DEFAULT_PANEL: PANEL.PROFILE,
            PANELS: [PANEL.PROFILE, PANEL.RESULT],
            DEFAULT_MODAL: null,
            MODALS: []
        },
        [VIEW.SETTINGS]: {
            DEFAULT_PANEL: PANEL.SETTINGS,
            PANELS: [PANEL.SETTINGS],
            DEFAULT_MODAL: null,
            MODALS: [MODAL.ABOUT_US]
        },
        [VIEW.NOTIFICATION]: {
            DEFAULT_PANEL: PANEL.NOTIFICATION,
            PANELS: [PANEL.NOTIFICATION],
            DEFAULT_MODAL: null,
            MODALS: []
        },
        [VIEW.ERROR]: {
            DEFAULT_PANEL: PANEL.ERROR,
            PANELS: [PANEL.ERROR],
            DEFAULT_MODAL: null,
            MODALS: []
        },
        [VIEW.JOINT_PASSAGE]: {
            DEFAULT_PANEL: PANEL.COOPERATIVE_PASSAGE_MAIN,
            PANELS: [PANEL.COOPERATIVE_PASSAGE_MAIN],
            DEFAULT_MODAL: null,
            MODALS: []
        }
    }
};
