import { Avatar, ModalPage, ModalPageHeader, Search, Separator, SimpleCell, Spacing, Spinner, Title } from "@vkontakte/vkui";
import { FriendSearchModalProps } from "./types";
import { FC } from "react";
import styles from './FriendSearchModal.module.css';

export const FriendSearchModal: FC<FriendSearchModalProps> = ({ 
id,
friends,
onFriendClick,
searchText,
onSearchTextChange,
loading
}) => {
    return (
        <ModalPage id={id} settlingHeight={10}>
            <ModalPageHeader>Поиск друга</ModalPageHeader>
            <Search
                value={searchText}
                onChange={(e) => onSearchTextChange(e.target.value)}
            />
            <Spacing size={12} />
            <Separator />
            <Spacing size={12} />
            {loading && 
                <div className={styles.container}>
                    <Spinner size="large"/>
                </div>
            }
            <div className={styles.friendsContainer}>
            {!loading &&friends && friends.map((friend) => {
                return (
                    <SimpleCell
                    key={id}
                    before={<Avatar size={48} src={friend.photoSrc} />}
                    subtitle={friend.subtitle}
                    onClick={() => onFriendClick(friend.vkId)}
                  >
                    {friend.name}
                  </SimpleCell>
                );
            })}
            </div>
            {!loading && !friends &&
                <div className={styles.container}>
                    <Title>Не удалось загрузить друзей</Title>
                </div>
            }
            {!loading && friends?.length === 0 &&
                <div className={styles.container}>
                    <Title>Не удалось найти друзей</Title>
                </div>
            }
        </ModalPage>
    );
};
