import { View } from "@vkontakte/vkui";
import { FC } from "react";
import { CooperativePassageViewProps } from "./types";
import { MainPanel } from "./panels/MainPanel";
import { PANEL } from "../../constants/Panel";
import { IncomingRequestsPanel } from "./panels/IncomingRequestsPanel";
import { SubmittedRequestsPanel } from "./panels/SubmittedRequestsPanel";
import { AddFriendPanel } from "./panels/AddFriendPanel";
import { FriendListPanel } from "./panels/FriendListPanel";
import { TestPanel } from "./panels/TestPanel";
import { ResultPanel } from "./panels/ResultPanel";

export const CooperativePassageView: FC<CooperativePassageViewProps> = ({ id, activePanel }) => {
    return (
        <View id={id} activePanel={activePanel}>
            <MainPanel id={PANEL.COOPERATIVE_PASSAGE_MAIN}/>
            <IncomingRequestsPanel id={PANEL.COOPERATIVE_PASSAGE_INCOMING_REQUESTS}/>
            <SubmittedRequestsPanel id={PANEL.COOPERATIVE_PASSAGE_SUBMITTED_REQUESTS}/>
            <AddFriendPanel id={PANEL.COOPERATIVE_PASSAGE_ADD_FRIEND}/>
            <FriendListPanel id={PANEL.COOPERATIVE_PASSAGE_FRIEND_LIST}/>
            <TestPanel id={PANEL.COOPERATIVE_PASSAGE_COOPERATIVE_TEST}/>
            <ResultPanel id={PANEL.COOPERATIVE_PASSAGE_RESULT}/>
        </View>
    );
}