export const PANEL = {
  TESTS: 'tests',
  CURRENT_TEST: 'currentTest',
  ADDING_TEST_FORM: 'addingTestForm',
  PROFILE: 'profile',
  RESULT: 'result',
  SETTINGS: 'settings',
  NOTIFICATION: 'notification',
  ERROR: 'error',
  COOPERATIVE_PASSAGE_MAIN: 'cooperativePassageMain',
  COOPERATIVE_PASSAGE_INCOMING_REQUESTS: 'incomingRequest',
  COOPERATIVE_PASSAGE_SUBMITTED_REQUESTS: 'submittedRequests',
  COOPERATIVE_PASSAGE_ADD_FRIEND: 'addFriend',
  COOPERATIVE_PASSAGE_FRIEND_LIST: 'friendList',
  COOPERATIVE_PASSAGE_COOPERATIVE_TEST: 'cooperativeTest',
  COOPERATIVE_PASSAGE_RESULT: 'cooperativeResult',
} as const;

export type PanelType = typeof PANEL[keyof typeof PANEL];

export const panelsWithNavigation = [
  PANEL.TESTS,
  PANEL.PROFILE,
  PANEL.SETTINGS,
  PANEL.COOPERATIVE_PASSAGE_MAIN,
  PANEL.COOPERATIVE_PASSAGE_ADD_FRIEND,
  PANEL.COOPERATIVE_PASSAGE_COOPERATIVE_TEST,
  PANEL.COOPERATIVE_PASSAGE_FRIEND_LIST,
  PANEL.COOPERATIVE_PASSAGE_INCOMING_REQUESTS,
  PANEL.COOPERATIVE_PASSAGE_SUBMITTED_REQUESTS
];

export const isPanelWithNavigation = (activePanel: PanelType) => {
  return panelsWithNavigation.some((panel) => panel === activePanel);
};
