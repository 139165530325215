import { FC } from "react";
import { TestCardProps } from "./types";

import styles from './TestCard.module.css';
import { Spinner, Title } from "@vkontakte/vkui";

export const TestCard: FC<TestCardProps> = ({ 
    test,
    friends,
    friendsThatPassedTest,
    loading,
    onClick,
    appearance,
    deviceType
}) => {
    const render = () => {
        if (loading) {
            return (<Spinner/>);
        }
        else if(!loading){
            return <>{friendsThatPassedTest?.length}/{friends?.length} друзей</>;
        }
    }

    return (
        <div className={styles['container_' + appearance] + ' ' + styles['container_' + appearance + '_' + deviceType]} onClick={onClick}>
            <div className={styles['imageContainer_' + deviceType]}>
                <img className={styles.img} src={test.decorations.photo}/>
            </div>
            <div className={styles.titleContainer}>
                <Title level="2">{test.full_name}</Title>
            </div>
            <div className={styles['friendsInfo_' + deviceType]}>{render()}</div>
        </div>
    );
};
