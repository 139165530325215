import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './useAppDispatch';
import { getFriends } from '../api/VKWebApi';
import { updateFriends } from '../store/cooperativePassageSlice';
import { selectLocation } from '../store/routerSlice/routerSelectors';
import { selectUserFriendsInfo } from '../store/cooperativePassageSlice/selectors';

export const useGetFriends = () => {
  const dispatch = useAppDispatch();
  const friends = useSelector(selectUserFriendsInfo);
  const location = useSelector(selectLocation);

  return useCallback(async (accessToken: string) => {
    if (friends.length != 0) {
      return friends;
    }

    let newFriends;
    try {
      newFriends = await getFriends(accessToken);
    } catch (error) { 
      throw error;
    }

    dispatch(updateFriends(newFriends));
    return newFriends;
  }, [ dispatch, location]);
};
