import { Group, Panel, PanelHeader } from "@vkontakte/vkui"
import { MainPanelProps } from "./types";
import { FC } from "react";
import { DesktopNavigation } from "../../../../components/navigation/DesktopNavigation";
import { NAVIGATION } from "../../../../constants/Navigation";

import styles from './MainPanel.module.css';
import { NavigationMenu } from "../../components/NavigationMenu";
import { TestCard } from "./components/TestCard";
import { Icon28Menu } from "@vkontakte/icons";
import { MobileNavigationMenu } from "../../components/MobileNavigationMenu";

export const MainPanel: FC<MainPanelProps> = ({ 
id,
isDesktop,
tests,
isMenuOpen,
openMenu
 }) => {
    return (
        <Panel id={id}>
            <PanelHeader>Совместные прохождения: главная страница</PanelHeader>
            <Group>
                {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}        
                <div className={isMenuOpen && isDesktop ? styles.container_open : ''} style={{minHeight: window.innerHeight - 200}}>
                    {isDesktop ? <NavigationMenu/> : isMenuOpen ? <MobileNavigationMenu/> : <Icon28Menu onClick={openMenu} width={50} height={50}/>}
                    {(isDesktop || (!isDesktop && !isMenuOpen)) && 
                        <div className={isDesktop ? styles.testsDesktopContainer: styles.testsMobileContainer}>
                            {tests.map((test) => {
                                return (
                                    <TestCard test={test}/>
                                );
                            })}
                        </div>
                    }
                </div>
            </Group>
        </Panel>
    );
}