import { FC, useEffect, useState } from "react";
import { TestPanelContainerProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { TestPanel } from "./TestPanel";
import { selectCurrentCooperativeTest, selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import resultsApi from "../../../../api/ResultsApi";
import { selectResultByTestShortName } from "../../../../store/resultsSlice/resultsSelectors";

import store, { RootState } from "../../../../store";
import { getUsersCommonData } from "../../../../api/VKWebApi";
import { selectUserAccessToken, selectUserFriendsIds } from "../../../../store/userSlice/userSelectors";
import { useSetAccessToken } from "../../../../hooks/useSetAccessToken";
import { temperamentData } from "./constants";
import { useAppearance } from "@vkontakte/vkui";
import { useRouter } from "../../../../hooks/useRouter";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";

const TestPanelContainer: FC<TestPanelContainerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const appearance = useAppearance();
    const test = useSelector(selectCurrentCooperativeTest);
    const friendsIds = useSelector(selectUserFriendsIds);
    const accessToken = useSelector(selectUserAccessToken);
    const setAccessToken = useSetAccessToken('', ['friends']);
    const router = useRouter();
    const isMenuOpen = useSelector(selectIsMenuOpen);
    const dispatch = useDispatch()

    //@ts-ignore
    const result = useSelector((state: RootState) => selectResultByTestShortName(store.getState(), test.short_name))?.result[0].factors[0].name.split(' ')[4];

    const [friends, setFriends] = useState();
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const getFriendsResult = async() => {
            const friends = [];
            for (const friendId of friendsIds || []) {               
                //@ts-ignore            
                const result = (await resultsApi.getResults({isProcessed: true}, friendId)).filter((item) => item.test_short_name === test.short_name);
                if (result.length == 0) {
                    continue;
                }

                if (accessToken){
                    const userInfo = (await getUsersCommonData([friendId], accessToken))[0];
                    friends.push({
                        friendInfo: userInfo,
                        //@ts-ignore
                        result: result[0].result[0].factors[0].name.split(' ')[4],
                    });
                }
                else {
                    setAccessToken();
                    break;
                };
            }
            //@ts-ignore
            setFriends(friends);
            setLoading(false);
        }

        getFriendsResult();
    }, [accessToken, friendsIds]);

    const getTemperamentData = () => {
        //@ts-ignore
        return temperamentData[result];
    }

    const onBackClick = () => {
        router.back();
    }

     const openMenu = () => {
        dispatch(updateIsMenuOpen(true));
     }

    return (
        <TestPanel
            id={id}
            isDesktop={isDesktop}
            test={test}
            friends={friends}
            temperamentData={getTemperamentData()}
            loading={loading}
            appearance={appearance}
            onBackClick={onBackClick}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
        />
    );
};

export default TestPanelContainer;
