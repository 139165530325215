import { FC } from "react";
import { CooperativeSuggestionContainerProps } from "./types";
import { CooperativeSuggestion } from "./CooperativeSuggestion";
import { useRouter } from "../../../hooks/useRouter";
import { VIEW } from "../../../constants/View";
import { useAppearance } from "@vkontakte/vkui";

const CooperativeSuggestionContainer: FC<CooperativeSuggestionContainerProps> = () => {
    const router = useRouter();
    const appearance = useAppearance();

    const onClick = () => {
        router.forward({ view: VIEW.JOINT_PASSAGE});
    };

    return (
        <CooperativeSuggestion 
            onClick={onClick}
            appearance={appearance}
        />
    );
};

export default CooperativeSuggestionContainer;
