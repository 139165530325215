import { FC, useEffect, useState } from "react";
import { SubmittedRequestsPanelContainerProps } from "./types";
import { SubmittedRequestsPanel } from "./SubmittedRequestsPanel";
import { useDispatch, useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { useRouter } from "../../../../hooks/useRouter";
import { selectUserAccessToken } from "../../../../store/userSlice/userSelectors";
import { useSetAccessToken } from "../../../../hooks/useSetAccessToken";
import { getUsersCommonData } from "../../../../api/VKWebApi";
import { UserInfo } from "../../../../types/userInfo.type";
import { useAppearance } from "@vkontakte/vkui";
import { selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";
import usersApi from "../../../../api/UsersApi";

const SubmittedRequestsPanelContainer: FC<SubmittedRequestsPanelContainerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const router = useRouter();
    const accessToken = useSelector(selectUserAccessToken);
    const setAccessToken = useSetAccessToken('', ['friends']);
    const appearance = useAppearance();
    const isMenuOpen = useSelector(selectIsMenuOpen);
    const dispatch = useDispatch()

    const [usersData, setUsersData] = useState<UserInfo[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getUsers = async() => {
            if (accessToken) {
                const userSubmittedRequestsUsersIds = await usersApi.getUserSubmittedRequests();
                const usersData = await getUsersCommonData(userSubmittedRequestsUsersIds!, accessToken);
                setUsersData(usersData);
                setLoading(false);
            }
            else {
                setAccessToken();
            }
        }

        getUsers();
    }, [accessToken]);

    const onBackClick = () => {
        router.back();
    }

    const openMenu = () => {
        dispatch(updateIsMenuOpen(true));
    }

    return (
        <SubmittedRequestsPanel 
            id={id}
            isDesktop={isDesktop}
            onBackClick={onBackClick}
            usersData={usersData}
            loading={loading}
            appearance={appearance}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
        />
    );
};

export default SubmittedRequestsPanelContainer;
