import { connect } from 'react-redux';

import { 
  Icon24BillheadOutline, 
  Icon24UserSquareOutline, 
  Icon28SettingsOutline,
  Icon28Users,
} from '@vkontakte/icons';

import { RootState } from '../../../store';
import { selectView } from '../../../store/routerSlice/routerSelectors';

import { useRouter } from '../../../hooks/useRouter';

import { VIEW, ViewType } from '../../../constants/View';

import { DesktopNavigation, DesktopNavigationRestType } from './DesktopNavigation';

const DesktopNavigationContainer: React.FC<DesktopNavigationRestType> = (props) => {
  const router = useRouter();
  const setView = (view: ViewType) => router.forward({ view });

  return (
    <DesktopNavigation
      options={[
        {
          value: VIEW.TESTS,
          text: 'Тесты',
          label: <Icon24BillheadOutline/>,
          action: () => setView(VIEW.TESTS),
        },
        {
          value: VIEW.JOINT_PASSAGE,
          text: 'Совместное прохождение',
          label: <Icon28Users/>,
          action: () => setView(VIEW.JOINT_PASSAGE),
        },
        {
          value: VIEW.PROFILE,
          text: 'Профиль',
          label: <Icon24UserSquareOutline/>,
          action: () => setView(VIEW.PROFILE),
        },
        {
          value: VIEW.SETTINGS,
          text: 'Настройки',
          label: <Icon28SettingsOutline width={24} height={24} />,
          action: () => setView(VIEW.SETTINGS),
        },
      ]}
      {...props}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    activeView: selectView(state),
  };
}

export default connect(mapStateToProps)(DesktopNavigationContainer);
