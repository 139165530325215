import { FC, useEffect, useState } from "react";
import { FriendSearchModal } from "./FriendSearchModal";
import { FriendSearchModalContainerProps } from "./types";
import { useSetAccessToken } from "../../../../hooks/useSetAccessToken";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAccessToken, selectUserFriendsIds } from "../../../../store/userSlice/userSelectors";
import { useGetFriends } from "../../../../hooks/useGetFriends";
import { getOutUserCommonData } from "../../../../api/VKWebApi";
import { updateSelectedUser } from "../../../../store/cooperativePassageSlice";
import { useRouter } from "../../../../hooks/useRouter";
import { MODAL } from "../../../../constants/Modal";
import { UserInfo } from "../../../../types/userInfo.type";

const FriendSearchModalContianer: FC< FriendSearchModalContainerProps> = ({ id }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const setAccessToken = useSetAccessToken('',['friends']);
    const getFriends = useGetFriends();
    const accessToken = useSelector(selectUserAccessToken);

    const [friends, setFriends] = useState<UserInfo[]>();
    const [searchText, setSearchText] = useState<string>('');
    const [filteredFriends, setFilteredFriends] = useState<UserInfo[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (friends) {
            setFilteredFriends(!Boolean(searchText) ? friends : friends.filter((friend: UserInfo) => {
                return friend.name.toLowerCase().slice(0, searchText.length) === searchText.toLocaleLowerCase();
            }))
        } else {
            setFilteredFriends([]);
        }
    }, [searchText, friends]);

    useEffect(() => {
        const getAndSetFriends = async() => {
            if (accessToken) {
                const friends = await getFriends(accessToken);
                setFriends(friends);
                setLoading(false);
            }
            else {
                setAccessToken();
            }
        }

        setLoading(true);
        getAndSetFriends();
    }, [accessToken]);

    const onFriendClick = async(vkId: number) => {
        const data = await getOutUserCommonData(vkId);
        const userInfo = {
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            photo: data.photo_100
        } 

        dispatch(updateSelectedUser(userInfo));
        router.forward({ modal: MODAL.REQUEST_CONFIRMATION });
    };

    const onSearchTextChange = (value: string) => {
        setSearchText(value);
    }

    return (
        <FriendSearchModal 
            id={id} 
            friends={filteredFriends} 
            onFriendClick={onFriendClick} 
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            loading={loading}
        />
    );
};

export default FriendSearchModalContianer;
