import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { UserInfo } from '@vkontakte/vk-bridge';
import { UserType } from '../../types/user.type';

import { updateSettings } from '../settingsSlice';

import usersApi from '../../api/UsersApi';

import { isError } from '../../utils/isError';
import { SocialType } from '../../types/socialType.type';

type UserDataType = Partial<UserInfo> & {
  created_at?: number;
  granted_token_scope?: string[];
  access_token?: string;
  social?: SocialType;
};

type UserStateType = {
  data: UserDataType | null;
  loading: boolean;
  error: string | null;
};

export const fetchUser = createAsyncThunk<UserType, undefined, { rejectValue: string }>(
  'user/fetchUser',
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const data = await usersApi.getUser();
      dispatch(updateSettings(data.params));
      
      return data;
    }
    catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      };
    }
  }
);

const userInitialState: UserStateType = {
  data: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    updateUser(state, action: PayloadAction<UserDataType>) {
      if (state.data) {
        Object.assign(state.data, action.payload);
      } else {
        state.data = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const { vk_id, params, results, ...rest } = action.payload;

        if (state.data) {
          Object.assign(state.data, rest);
        } else {
          state.data = rest;
        }

        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
