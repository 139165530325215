export const data =  {
    'Холерик' : {
        'Сангвиник': {
            description: 'Отношения между сангвиником и холериком могут быть интересными и динамичными, поскольку оба типа темперамента имеют много энергии и любят активность, но у них есть и значительные различия, которые могут как обогащать их взаимодействие, так и создавать трудности.',
            advantages: [
                {
                    name: 'Активность и энергичность',
                    description: [
                        'Сангвиник и холерик обладают высокой энергией, что делает их взаимодействие ярким и насыщенным.',
                        'Вместе они могут быть вдохновляющими лидерами или просто активными партнёрами в любой деятельности.'
                    ]
                },
                {
                    name: 'Любовь к изменениям и движению',
                    description: [
                        'Оба типа любят новые впечатления, приключения и стремятся избегать рутины, что делает их совместное времяпрепровождение увлекательным.'
                    ]
                },
                {
                    name: 'Общительность',
                    description: ['Сангвиник склонен к лёгкому общению, а холерик может брать на себя инициативу и задавать тон, что делает их беседы продуктивными и насыщенными']
                }
            ],
            disadvantages: [
                {
                    name: 'Импульсивность холерика и эмоциональная лёгкость сангвиника',
                    description: [
                        'Холерик может быть слишком резким или агрессивным, что может обижать сангвиника, который избегает конфронтаций.',
                        'С другой стороны, сангвиник может показаться холерику легкомысленным или недостаточно серьёзным.'
                    ]
                },
                {
                    name: 'Разные подходы к целям',
                    description: [
                        'Холерик часто нацелен на результат, проявляет решительность и требовательность.',
                        'Сангвиник, более расслабленный и ориентированный на процесс, может вызывать раздражение у холерика своей поверхностностью.'
                    ]
                },
                {
                    name: 'Риск выгорания',
                    description: ['Постоянная активность и отсутствие баланса могут истощить их обоих, если они не научатся вместе отдыхать и расслабляться.']
                }
            ],
            conclusion: 'Вместе эти два темперамента могут создать яркий, насыщенный и эффективный тандем, если научатся учитывать потребности и особенности друг друга.',
        },
        'Флегматик': {
            description: 'Отношения между холериком и флегматиком представляют собой встречу противоположностей, что может быть как плюсом, так и вызовом. Холерик и флегматик отличаются по характеру, скорости реакции и подходу к жизни, что создаёт уникальную динамику в их взаимодействии.',
            advantages: [
                {
                    name: 'Взаимодополняемость',
                    description: [
                        'Холерик приносит в отношения энергию, мотивацию и динамику',
                        'Флегматик добавляет стабильность, спокойствие и рассудительность, помогая уравновешивать бурные порывы холерика.'
                    ]
                },
                {
                    name: 'Роль уравновешивающего фактора',
                    description: [
                        'Флегматик помогает холерику замедлиться и обдумывать свои решения, вместо того чтобы действовать импульсивно.',
                        'Холерик стимулирует флегматика быть более активным, не застревать в рутине.'
                    ]
                },
                {
                    name: 'Распределение ролей',
                    description: [
                        'Холерик может брать на себя лидерство, если флегматику это комфортно.',
                        'Флегматик чаще соглашается с инициативами холерика, избегая конфликтов',
                    ]
                },
                {
                    name: 'Эмоциональная поддержка',
                    description: [
                        'Холерик может вдохновлять флегматика своей энергией.',
                        'Флегматик может быть "тихой гаванью" для холерика в моменты стресса.',
                    ]
                }
            ],
            disadvantages: [
                {
                    name: 'Разница в темпах',
                    description: [
                        'Холерик действует быстро и ожидает такого же темпа от других, что может раздражать его при медлительности флегматика.',
                        'Флегматик, напротив, может чувствовать давление и усталость от активности холерика.'
                    ]
                },
                {
                    name: 'Реакция на конфликты',
                    description: [
                        'Холерик склонен высказывать эмоции напрямую и бурно, тогда как флегматик старается избегать конфликтов, что может приводить к недоразумениям.',
                        'Флегматик может "закрываться" от излишне эмоционального напора холерика.'
                    ]
                },
                {
                    name: 'Распределение инициативы',
                    description: [
                        'Холерику может казаться, что флегматик недостаточно активно участвует в жизни или избегает принятия важных решений.',
                        'Флегматика может утомлять чрезмерная активность и стремление холерика контролировать всё.'
                    ]
                },
                {
                    name: 'Энергетическая несбалансированность',
                    description: [
                        'Холерик часто хочет быстрых изменений, в то время как флегматик предпочитает стабильность и долгосрочные планы.',
                    ]
                }
            ],
            conclusion: 'Отношения между холериком и флегматиком могут быть успешными, если оба научатся уважать и принимать различия друг друга. Холерик вдохновляет, а флегматик уравновешивает, создавая тандем, в котором можно достичь баланса энергии и стабильности.',
        },
        'Холерик': {
            description: 'Отношения между двумя холериками — это столкновение сильных личностей, наполненных энергией, амбициями и стремлением к лидерству. Такая пара может быть крайне яркой и продуктивной, но также обладает высоким риском конфликтов из-за схожести темпераментов.',
            advantages: [
                {
                    name: 'Энергия и активность',
                    description: [
                        'Оба партнёра полны жизненных сил, что делает их союз динамичным и захватывающим.',
                        'Они способны вместе добиваться больших целей, поддерживая друг друга в стремлении к успеху.'
                    ]
                },
                {
                    name: 'Понимание друг друга',
                    description: [
                        'Холерики хорошо понимают друг друга, так как оба обладают стремлением к лидерству и быстротой в принятии решений',
                        'Они ценят силу, прямолинейность и напор, что может способствовать взаимному уважению.'
                    ]
                },
                {
                    name: 'Амбиции и драйв',
                    description: [
                        'Такой союз может стать источником мощного вдохновения и мотивации, так как оба стремятся к лидерству и достижениям.',
                    ]
                },
                {
                    name: 'Яркость и насыщенность отношений',
                    description: [
                        'Холерики любят насыщенную событиями жизнь, и их отношения редко бывают скучными.',
                    ]
                }
            ],
            disadvantages: [
                {
                    name: 'Соперничество',
                    description: [
                        'Оба стремятся к доминированию, что может вызывать борьбу за лидерство.',
                        'Конкуренция может стать серьёзной проблемой, особенно если один из партнёров воспринимает успех другого как угрозу.'
                    ]
                },
                {
                    name: 'Импульсивность и конфликты',
                    description: [
                        'Холерики склонны к вспышкам гнева и могут резко реагировать на разногласия.',
                        'Частые эмоциональные всплески могут перерастать в затяжные конфликты, так как оба не хотят уступать.'
                    ]
                },
                {
                    name: 'Нехватка гибкости',
                    description: [
                        'Оба могут быть слишком упрямыми и непреклонными, что затрудняет нахождение компромиссов.',
                        'Это может привести к тому, что спор перерастёт в противостояние.'
                    ]
                },
                {
                    name: 'Эмоциональное истощение',
                    description: [
                        'Постоянное напряжение и борьба могут истощить обоих партнёров, даже если на начальном этапе это кажется захватывающим.',
                    ]
                }
            ],
            conclusion: 'Отношения между двумя холериками могут быть либо взрывной смесью конфликтов, либо мощным союзом двух сильных личностей. Успех такого тандема зависит от готовности обоих партнёров работать над собой, учиться идти на компромиссы и уважать друг друга. Если им удаётся справиться с соперничеством и контролировать свою импульсивность, такие отношения могут стать невероятно яркими и продуктивными.',
        },
        'Меланхолик': {
            description: 'Отношения между холериком и меланхоликом часто являются сложным, но потенциально глубоким союзом, в котором яркая энергия и решительность холерика сталкиваются с чувствительностью и склонностью к самоанализу меланхолика. У этих темпераментов есть существенные различия, которые могут как дополнять друг друга, так и приводить к трениям.',
            advantages: [
                {
                    name: 'Взаимодополняемость',
                    description: [
                        'Холерик приносит в отношения энергию, уверенность и способность быстро принимать решения.',
                        'Меланхолик добавляет глубину, эмоциональную тонкость и способность к анализу, что помогает холерику принимать более взвешенные решения.'
                    ]
                },
                {
                    name: 'Стимуляция для меланхолика',
                    description: [
                        'Холерик может «подтолкнуть» меланхолика к действию, помочь преодолеть его склонность к сомнениям и нерешительности.',
                        'Энергия холерика может вдохновлять меланхолика и наполнять его жизнь новыми впечатлениями.'
                    ]
                },
                {
                    name: 'Эмоциональная поддержка',
                    description: [
                        'Меланхолик способен понять эмоциональные перепады холерика и предложить поддержку в трудные моменты.',
                        'Холерик может оберегать меланхолика, помогая ему справляться с чувствительностью и страхами.'
                    ]
                },
                {
                    name: 'Сильный лидер и чувствительный советник',
                    description: [
                        'Холерик может взять на себя лидерство, а меланхолик — выступать в роли мудрого советника, обращающего внимание на детали и последствия.',
                    ]
                }
            ],
            disadvantages: [
                {
                    name: 'Импульсивность холерика и ранимость меланхолика',
                    description: [
                        'Холерик может быть резким и требовательным, что легко ранит чувствительного меланхолика.',
                        'Если холерик не контролирует свои эмоции, это может привести к замкнутости меланхолика.'
                    ]
                },
                {
                    name: 'Разный темп жизни',
                    description: [
                        'Холерик привык действовать быстро и решительно, тогда как меланхолик склонен к долгому обдумыванию и переживаниям.',
                        'Холерик может воспринимать меланхолика как слишком медленного или нерешительного, а меланхолик — холерика как поверхностного и нетерпеливого.'
                    ]
                },
                {
                    name: 'Риск подавления меланхолика',
                    description: [
                        'Доминирующая натура холерика может подавить меланхолика, заставив его чувствовать себя неуверенно и незначительно.',
                    ]
                },
                {
                    name: 'Эмоциональное истощение',
                    description: [
                        'Холерику сложно понять глубокую эмоциональность меланхолика, а меланхолик может устать от бурного характера холерика.',
                    ]
                }
            ],
            conclusion: 'Отношения между холериком и меланхоликом могут быть сложными, но при правильном подходе это союз, в котором каждый партнёр растёт и развивается. Холерик вдохновляет меланхолика на действия и преодоление страхов, а меланхолик помогает холерику стать более чутким, вдумчивым и внимательным к деталям. Такой союз требует терпения, взаимного уважения и умения учитывать особенности друг друга.',
        }
    }
};

//@ts-ignore
data['Сангвиник'] = {
    'Холерик': data['Холерик']['Сангвиник'],
    'Сангвиник': {
        description: 'Отношения между двумя сангвиниками представляют собой союз двух ярких, жизнерадостных и общительных людей. Эти отношения обычно наполнены энергией, весельем и активностью, но также могут сталкиваться с определёнными вызовами из-за склонности сангвиников к легкомысленности и непостоянству.',
        advantages: [
            {
                name: 'Похожесть характеров',
                description: [
                    'Оба партнёра понимают друг друга благодаря схожим взглядам на жизнь, любви к общению и стремлению к удовольствию.',
                ]
            },
            {
                name: 'Весёлый и активный стиль жизни',
                description: [
                    'Сангвиники легко находят общие интересы, любят приключения и создают атмосферу лёгкости и радости.',
                    'В их паре редко бывает скучно, так как оба готовы к новым впечатлениям.'
                ]
            },
            {
                name: 'Хорошая коммуникация',
                description: [
                    'Они общительны и открыты, что помогает легко обсуждать любые вопросы.',
                    'Оба ценят лёгкость в общении и не склонны к излишним конфликтам.'
                ]
            },
            {
                name: 'Спонтанность и творчество',
                description: [
                    'Их отношения полны импульсивных решений, поездок, развлечений и экспериментов',
                ]
            }
        ],
        disadvantages: [
            {
                name: 'Нехватка глубины',
                description: [
                    'Оба могут быть слишком поверхностными, избегая серьёзных тем или долгосрочных обязательств.',
                    'Взаимное стремление к лёгкости может мешать развитию отношений на более глубоком уровне.'
                ]
            },
            {
                name: 'Склонность к непостоянству',
                description: [
                    'Сангвиники часто меняют свои интересы и желания, что может привести к нестабильности в отношениях.',
                    'Оба могут быть склонны к скуке, если отношения становятся рутинными.'
                ]
            },
            {
                name: 'Импульсивность',
                description: [
                    'Спонтанные решения могут создавать хаос, особенно если оба не думают о последствиях.',
                    'Импульсивность в финансах, планах или обещаниях может вызывать конфликты.',
                ]
            },
            {
                name: 'Сложности с ответственностью',
                description: [
                    'Оба сангвиника могут избегать серьёзных обязанностей или откладывать решение важных вопросов.',
                ]
            }
        ],
        conclusion: 'Отношения между двумя сангвиниками обычно лёгкие, весёлые и яркие, но для их долгосрочного успеха необходимо развивать ответственность, эмоциональную глубину и устойчивость. Если оба партнёра научатся балансировать свою любовь к свободе и развлечениям с осознанностью и заботой о будущем, их союз может стать источником радости и вдохновения для обоих.',
    },
    'Флегматик': {
        description: 'Отношения между сангвиником и флегматиком представляют собой союз противоположностей, который может быть гармоничным благодаря их взаимодополняющим качествам. Сангвиник привносит в отношения энергию, оптимизм и динамику, тогда как флегматик обеспечивает стабильность, терпение и надёжность. Эти отношения часто оказываются прочными, если оба партнёра готовы учитывать различия в своих темпераментах.',
        advantages: [
            {
                name: 'Взаимодополняемость',
                description: [
                    'Сангвиник активен, жизнерадостен и полон идей, что помогает флегматику выходить из зоны комфорта и оживлять свою повседневность.',
                    'Флегматик, в свою очередь, привносит в жизнь сангвиника спокойствие, баланс и структурированность.'
                ]
            },
            {
                name: 'Стабильность',
                description: [
                    'Флегматик терпелив и уравновешен, что помогает сглаживать импульсивные и хаотичные черты сангвиника.',
                    'Это способствует созданию стабильной и безопасной атмосферы в отношениях.'
                ]
            },
            {
                name: 'Эмоциональная поддержка',
                description: [
                    'Флегматик умеет выслушать и понять сангвиника, даже если тот не всегда последователен в своих желаниях.',
                    'Сангвиник способен вдохновить флегматика на действие, добавляя в его жизнь больше эмоций и разнообразия.'
                ]
            },
            {
                name: 'Разделение ролей',
                description: [
                    'Сангвиник часто берёт на себя социальные аспекты отношений, поддерживает общение с окружающими, в то время как флегматик выполняет роль «тихой гавани».',
                ]
            }
        ],
        disadvantages: [
            {
                name: 'Разный темп жизни',
                description: [
                    'Сангвиник привык к быстроте и активности, в то время как флегматик предпочитает более медленный и размеренный ритм.',
                    'Сангвиник может считать флегматика слишком пассивным, а флегматик — сангвиника слишком торопливым.'
                ]
            },
            {
                name: 'Склонность к избеганию проблем',
                description: [
                    'Флегматик может избегать конфликтов, предпочитая отмалчиваться, что вызывает у сангвиника недоумение или раздражение.',
                    'Сангвиник может быстро забывать или игнорировать важные детали, что не устраивает флегматика.'
                ]
            },
            {
                name: 'Риск однообразия',
                description: [
                    'Сангвиник может скучать, если флегматик не поддерживает его стремление к новым впечатлениям.',
                    'Флегматик может чувствовать себя истощённым от постоянной активности сангвиника.',
                ]
            },
            {
                name: 'Разный подход к ответственности',
                description: [
                    'Флегматик склонен быть более надёжным и последовательным, в то время как сангвиник может быть легкомысленным и забывчивым.',
                ]
            }
        ],
        conclusion: 'Отношения сангвиника и флегматика могут быть гармоничными и долгосрочными, если оба партнёра готовы учитывать особенности друг друга. Сангвиник привносит в отношения энергию и динамику, а флегматик — стабильность и терпимость. Этот союз требует терпения, взаимопонимания и стремления к балансу, но в итоге может стать прочным и счастливым.',
    },
    'Меланхолик': {
        description: 'Отношения между сангвиником и меланхоликом представляют собой союз противоположностей, в котором оптимизм и легкость сангвиника сталкиваются с глубокой эмоциональностью и склонностью к рефлексии меланхолика. Эти различия могут как обогащать отношения, так и создавать сложности, если партнёры не готовы учитывать особенности друг друга.',
        advantages: [
            {
                name: 'Взаимодополняемость',
                description: [
                    'Сангвиник привносит в жизнь меланхолика лёгкость, радость и способность видеть позитив даже в сложных ситуациях.',
                    'Меланхолик добавляет в отношения глубину, чуткость и способность к размышлениям, что помогает сангвинику быть более осознанным.'
                ]
            },
            {
                name: 'Эмоциональная поддержка',
                description: [
                    'Сангвиник может вдохновить меланхолика и помочь ему выйти из состояния грусти или сомнений.',
                    'Меланхолик способен быть внимательным и заботливым партнёром, который выслушает и поддержит сангвиника в моменты стресса.'
                ]
            },
            {
                name: 'Разнообразие в отношениях',
                description: [
                    'Различие в темпераментах делает союз насыщенным: меланхолик обогащает его внутренним содержанием, а сангвиник — внешними впечатлениями.',
                ]
            },
            {
                name: 'Общение и вдохновение',
                description: [
                    'Сангвиник может научить меланхолика радоваться жизни, а меланхолик — показать ценность глубокой эмоциональной связи.',
                ]
            }
        ],
        disadvantages: [
            {
                name: 'Разное восприятие мира',
                description: [
                    'Сангвиник видит мир легко и позитивно, тогда как меланхолик склонен к пессимизму и переживаниям. Это может создавать непонимание.',
                    'Сангвиник может считать меланхолика слишком замкнутым или склонным к драматизации, а меланхолик — сангвиника поверхностным и невнимательным к его эмоциям.'
                ]
            },
            {
                name: 'Риск эмоционального истощения',
                description: [
                    'Сангвиник может не понять глубокой чувствительности меланхолика и, даже неосознанно, ранить его словами или поступками.',
                    'Меланхолик может уставать от энергии и активности сангвиника, воспринимая его как слишком шумного или нетерпеливого.'
                ]
            },
            {
                name: 'Склонность к избеганию проблем',
                description: [
                    'Сангвиник может избегать серьёзных тем или разговоров, которые важны для меланхолика.',
                    'Меланхолик, в свою очередь, может замыкаться в себе, если чувствует недостаток поддержки.',
                ]
            },
            {
                name: 'Разное отношение к обязательствам',
                description: [
                    'Сангвиник может быть непостоянным и забывчивым, что раздражает меланхолика, для которого важны надёжность и последовательность.',
                ]
            }
        ],
        conclusion: 'Отношения между сангвиником и меланхоликом могут быть успешными и гармоничными, если оба партнёра научатся принимать различия в своих темпераментах. Сангвиник вносит в союз энергию, оптимизм и спонтанность, а меланхолик добавляет глубину, чувственность и осознанность. Этот союз требует терпения, взаимного уважения и готовности поддерживать друг друга.',
    },
};

//@ts-ignore
data['Флегматик'] = {
    'Холерик': data['Холерик']['Флегматик'],
    //@ts-ignore
    'Сангвиник': data['Сангвиник']['Флегматик'],
    'Флегматик': {
        description: 'Отношения между двумя флегматиками часто бывают стабильными, мирными и гармоничными. Их схожий темперамент создаёт атмосферу покоя и взаимного уважения, однако может не хватать динамики и активности, что иногда приводит к застою или скуке. Тем не менее, такой союз часто отличается прочностью и длительностью.',
        advantages: [
            {
                name: 'Стабильность и надёжность',
                description: [
                    'Оба партнёра склонны к спокойному образу жизни и ценят стабильность, что делает отношения предсказуемыми и безопасными.',
                    'Конфликты в таком союзе редки, так как оба предпочитают избегать споров и находить компромиссы.'
                ]
            },
            {
                name: 'Терпимость и уважение',
                description: [
                    'Флегматики терпимы к недостаткам друг друга, редко критикуют и склонны поддерживать партнёра.',
                    'Они ценят личное пространство и не пытаются его нарушать, что способствует гармонии.'
                ]
            },
            {
                name: 'Эмоциональная совместимость',
                description: [
                    'Оба партнёра обладают уравновешенным характером и не склонны к вспышкам гнева или бурным эмоциям, что создаёт спокойную атмосферу.',
                ]
            },
            {
                name: 'Сходные ритмы жизни',
                description: [
                    'Оба предпочитают размеренность, любят планировать и не склонны к спонтанным, резким переменам. Это упрощает совместное принятие решений.',
                ]
            }
        ],
        disadvantages: [
            {
                name: 'Отсутствие инициативы',
                description: [
                    'Флегматики склонны к пассивности, и в их отношениях может не хватать драйва, страсти и амбиций.',
                    'Никто из них не стремится брать на себя роль лидера, что может привести к застою и отсутствию развития.'
                ]
            },
            {
                name: 'Риск рутины и скуки',
                description: [
                    'Схожий темперамент и склонность к однообразию могут сделать отношения монотонными.',
                    'Оба могут избегать экспериментов и новых впечатлений, что постепенно снижает интерес друг к другу.'
                ]
            },
            {
                name: 'Избегание проблем',
                description: [
                    'Флегматики часто избегают обсуждения сложных тем или конфликтов, что может приводить к накоплению недовольства',
                    'Партнёры могут замалчивать свои потребности, что в итоге создаёт недопонимание.',
                ]
            },
            {
                name: 'Недостаток эмоциональной насыщенности',
                description: [
                    'Оба партнёра не склонны к выражению ярких эмоций, что может оставить ощущение "холодности" или недостаточной близости',
                ]
            }
        ],
        conclusion: 'Отношения между двумя флегматиками имеют большой потенциал для создания крепкой, стабильной и мирной связи. Однако для того чтобы избежать рутины и пассивности, обоим партнёрам важно вносить в отношения немного динамики, быть более инициативными и работать над эмоциональной близостью. Если оба готовы к совместной работе над развитием союза, эти отношения могут стать прочными и гармоничными.',
    },
    'Меланхолик': {
        description: 'Отношения между флегматиком и меланхоликом часто бывают гармоничными, поскольку оба темперамента обладают склонностью к спокойствию, размышлениям и избеганию конфликтов. Флегматик привносит в союз стабильность и терпение, в то время как меланхолик добавляет эмоциональную глубину и чуткость. Однако различия в их характере могут создавать некоторые сложности, если партнёры не понимают особенности друг друга.',
        advantages: [
            {
                name: 'Стабильность и поддержка',
                description: [
                    'Флегматик обеспечивает меланхолику спокойную и надёжную атмосферу, в которой тот чувствует себя комфортно.',
                    'Меланхолик, в свою очередь, ценит терпение и надёжность флегматика, находя в нём «тихую гавань».'
                ]
            },
            {
                name: 'Эмоциональная чуткость',
                description: [
                    'Меланхолик добавляет в отношения глубину и помогает флегматику стать более внимательным к эмоциям и внутренним переживаниям.',
                    'Флегматик способен спокойно воспринимать перепады настроения меланхолика, не реагируя слишком остро.'
                ]
            },
            {
                name: 'Мягкость в общении',
                description: [
                    'Оба темперамента избегают резкости в словах и поступках, что способствует сохранению тёплой атмосферы в отношениях.',
                ]
            },
            {
                name: 'Склонность к размеренному ритму жизни',
                description: [
                    'И флегматик, и меланхолик предпочитают избегать бурных событий и быстро адаптируются к размеренному и предсказуемому ритму.',
                ]
            }
        ],
        disadvantages: [
            {
                name: 'Риск застоя',
                description: [
                    'Оба партнёра склонны к пассивности, что может привести к отсутствию развития в отношениях.',
                    'Флегматик редко проявляет инициативу, а меланхолик может избегать перемен из-за своей тревожности.'
                ]
            },
            {
                name: 'Чрезмерная эмоциональность меланхолика',
                description: [
                    'Меланхолик склонен к глубоким переживаниям и может погружаться в пессимизм, что для флегматика иногда кажется излишним.',
                    'Флегматик может воспринимать такие эмоции как требовательность, что вызывает усталость.'
                ]
            },
            {
                name: 'Избегание обсуждения проблем',
                description: [
                    'Флегматик и меланхолик склонны к молчанию, когда возникают трудности. Это может привести к недоразумениям или накоплению обид.',
                ]
            },
            {
                name: 'Недостаток ярких эмоций',
                description: [
                    'Для меланхолика отношения могут казаться слишком «прохладными» из-за невозмутимости флегматика.', 
                    'Флегматику может недоставать лёгкости и оптимизма в отношениях с меланхоликом.'
                ]
            }
        ],
        conclusion: 'Отношения между флегматиком и меланхоликом могут быть гармоничными и стабильными, если оба партнёра готовы работать над взаимным пониманием и уважением своих различий. Флегматик привносит спокойствие и устойчивость, а меланхолик — эмоциональную глубину и чувственность. Такой союз имеет потенциал для долгосрочного счастья, если партнёры будут избегать пассивности и открыто общаться о своих потребностях.',
    },
};

//@ts-ignore
data['Меланхолик'] = {
    'Холерик': data['Холерик']['Меланхолик'],
    //@ts-ignore
    'Сангвиник': data['Сангвиник']['Меланхолик'],
    //@ts-ignore
    'Флегматик': data['Флегматик']['Меланхолик'],
    'Меланхолик': {
        description: 'Отношения между двумя меланхоликами могут быть сложными, но одновременно очень глубокими и эмоционально насыщенными. Оба партнёра обладают чувствительностью, склонностью к самоанализу и эмоциональной глубиной. Если они находят общий язык и поддерживают друг друга, такие отношения могут быть очень сильными, однако их взаимодействие требует особого внимания из-за склонности к пессимизму и тревожности.',
        advantages: [
            {
                name: 'Эмоциональная близость',
                description: [
                    'Меланхолики хорошо понимают чувства друг друга и способны глубоко сопереживать.',
                    'Они ценят искренность и эмоциональную открытость, что укрепляет их связь.'
                ]
            },
            {
                name: 'Осмысленность и глубина',
                description: [
                    'Отношения двух меланхоликов часто наполнены философскими беседами, размышлениями о жизни и искренним интересом к внутреннему миру партнёра.',
                    'Они склонны строить отношения на честности и доверии.'
                ]
            },
            {
                name: 'Схожий ритм жизни:',
                description: [
                    'Оба предпочитают спокойствие и уединение, избегая суеты и излишней активности.',
                    'Им комфортно вместе проводить время в тихой, уютной атмосфере.'
                ]
            },
            {
                name: 'Чуткость к потребностям партнёра',
                description: [
                    'Меланхолики склонны внимательно относиться к эмоциям и желаниям другого человека, стараясь не причинять боли.',
                ]
            }
        ],
        disadvantages: [
            {
                name: 'Склонность к пессимизму',
                description: [
                    'Оба партнёра склонны зацикливаться на негативе, что может привести к общей эмоциональной подавленности.',
                    'Вместо взаимной поддержки они могут усиливать тревожность и уныние друг друга.'
                ]
            },
            {
                name: 'Избегание конфликтов',
                description: [
                    'Меланхолики боятся обидеть друг друга, из-за чего могут избегать открытого обсуждения проблем.',
                    'Это приводит к накоплению недовольства и скрытых обид.'
                ]
            },
            {
                name: 'Нехватка активности',
                description: [
                    'Из-за склонности к пассивности и склонности к размышлениям отношения могут застопориться, не получая необходимого импульса для развития.',
                    'Оба могут избегать действий, если не чувствуют уверенности.',
                ]
            },
            {
                name: 'Чрезмерная чувствительность',
                description: [
                    'Меланхолики могут легко обижаться друг на друга из-за невнимательных слов или мелких недоразумений.', 
                    'Взаимное эмоциональное напряжение иногда делает отношения более хрупкими.'
                ]
            }
        ],
        conclusion: 'Отношения между двумя меланхоликами могут быть очень глубокими и эмоционально насыщенными, но требуют работы над позитивным восприятием жизни и умением обсуждать проблемы. Если оба партнёра научатся поддерживать друг друга и избегать зацикливания на негативе, такой союз может стать примером искренности, душевной близости и взаимной заботы.',
    },
};
