import { useDispatch, useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { IncomingRequestsPanelContianerProps } from "./types";
import { FC, useEffect, useState } from "react";
import { IncomingRequestsPanel } from "./IncomingRequestsPanel";
import { getUsersCommonData } from "../../../../api/VKWebApi";
import { selectUserAccessToken } from "../../../../store/userSlice/userSelectors";
import { useSetAccessToken } from "../../../../hooks/useSetAccessToken";
import { useRouter } from "../../../../hooks/useRouter";
import { UserInfo } from "../../../../types/userInfo.type";
import { useAppearance } from "@vkontakte/vkui";
import { selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";
import usersApi from "../../../../api/UsersApi";

const IncomingRequestsPanelContianer: FC<IncomingRequestsPanelContianerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const accessToken = useSelector(selectUserAccessToken);
    const setAccessToken = useSetAccessToken('', ['friends']);
    const router = useRouter();
    const appearance = useAppearance(); 
    const isMenuOpen = useSelector(selectIsMenuOpen);
    const dispatch = useDispatch()

    const [usersData, setUsersData] = useState<UserInfo[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getUserIncomingRequests = async() => {
            if (accessToken) {
                const userIncomingRequestsUsersIds = await usersApi.getUserIncomingRequests();
                const usersData = await getUsersCommonData(userIncomingRequestsUsersIds!, accessToken);
                setUsersData(usersData);
                setLoading(false);
            }
            else {
                setAccessToken();
            }
        }

        getUserIncomingRequests();
    }, [accessToken]);

    const onBackClick = () => {
        router.back();
    };

    const openMenu = () => {
        dispatch(updateIsMenuOpen(true));
    }

    return (
        <IncomingRequestsPanel
            isDesktop={isDesktop}
            id={id} 
            usersData={usersData || []}
            onBackClick={onBackClick}
            loading={loading}
            appearance={appearance}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
        />
    );
};

export default IncomingRequestsPanelContianer;
