export const VIEW = {
  TESTS: 'tests',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  NOTIFICATION: 'notification',
  ERROR: 'error',
  JOINT_PASSAGE: 'jointPassage',
} as const;

export type ViewType = typeof VIEW[keyof typeof VIEW];
