import { FC } from "react";
import { FriendListPanelProps } from "./types";
import { Group, Panel, PanelHeader, PanelHeaderBack, Spinner, Title } from "@vkontakte/vkui";
import { NAVIGATION } from "../../../../constants/Navigation";
import { DesktopNavigation } from "../../../../components/navigation/DesktopNavigation";
import { NavigationMenu } from "../../components/NavigationMenu";

import styles from './FriendListPanel.module.css';
import { UserItem } from "./components/UserItem";
import { MobileNavigationMenu } from "../../components/MobileNavigationMenu";
import { Icon28Menu } from "@vkontakte/icons";

export const FriendListPanel: FC<FriendListPanelProps> = ({ 
    id,
    isDesktop,
    friendsData,
    loading,
    appearance,
    onBackClick,
    isMenuOpen,
    openMenu,
}) => {
    return (
        <Panel id={id}>
            <PanelHeader onClick={onBackClick} before={<PanelHeaderBack/>}>Совместные прохождения: список друзей</PanelHeader>
            <Group>
                {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}         
                <div className={isDesktop && isMenuOpen ? styles.container_open: ''}>
                    {isDesktop ? <NavigationMenu/> : isMenuOpen ? <MobileNavigationMenu/> : <Icon28Menu onClick={openMenu} width={50} height={50}/>}
                    {(isDesktop || (!isDesktop && !isMenuOpen)) &&
                        <div className={styles.panelContainer}>
                            <div className={styles.titleContainer}>
                                <Title>Список друзей</Title>
                            </div>
                            {loading &&
                             <Spinner/>
                            }
                            {!loading && friendsData.length === 0 &&
                                <div className={styles.titleContainer}>
                                    <Title level="2">У вас пока нет друзей</Title>
                                </div>
                            }
                            {friendsData &&
                                friendsData.map((friendData , index) => {
                                    return(
                                        <div className={index % 2 === 0 ? styles['backgroundEven_' + appearance] : styles['backgroundOdd_' + appearance]}>
                                            <UserItem userData={friendData}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </Group>
        </Panel>
    );
};
