import { FC, useState } from "react";
import { MainPanel } from "./MainPanel";
import { MainPanelContainerProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { selectCooperativeTests } from "../../../../store/testsSlice/testsSelectors";
import { selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";

const MainPanelContainer: FC<MainPanelContainerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const tests = useSelector(selectCooperativeTests);
    const isMenuOpen = useSelector(selectIsMenuOpen);
    const dispatch = useDispatch();

    const openMenu = () => {
        dispatch(updateIsMenuOpen(true));
    }

    return (
        <MainPanel 
            id={id} 
            isDesktop={isDesktop}
            tests={tests}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
        />
    );
};

export default MainPanelContainer;
