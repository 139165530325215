import { PANEL } from "../../../../constants/Panel";
import { useRouter } from "../../../../hooks/useRouter";
import { NavigationMenu } from "./NavigationMenu";
import { selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";
import { useAppearance } from "@vkontakte/vkui";

const NavigationMenuContainer = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const appearance = useAppearance();

    const isMenuOpen = useSelector(selectIsMenuOpen);

    const onMainPanelButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_MAIN});
    };

    const onFriednListPanelButtonClick = () => {
         router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_FRIEND_LIST });
    }

    const onAddFriendPanelButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_ADD_FRIEND});
    };

    const onIncomingRequestsButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_INCOMING_REQUESTS });
    };

    const onSubmittedRequestsButtonClick = () => {
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_SUBMITTED_REQUESTS });
    }

     const toggleMenu = () => {
        console.log(1);
        dispatch(updateIsMenuOpen(!isMenuOpen));
    }

    return (
        <NavigationMenu
            onMainPanelButtonClick={onMainPanelButtonClick}
            onFriednListPanelButtonClick={onFriednListPanelButtonClick}
            onAddFriendPanelButtonClick={onAddFriendPanelButtonClick}
            onIncomingRequestsButtonClick={onIncomingRequestsButtonClick}
            onSubmittedRequestsButtonClick={onSubmittedRequestsButtonClick}
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            appearance={appearance}
        />
    );
};

export default NavigationMenuContainer;
