import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import testsReducer from './testsSlice';
import testReducer from './testSlice';
import resultsReducer from './resultsSlice';
import resultReducer from './resultSlice';
import userReducer from './userSlice';
import settingsReducer from './settingsSlice';
import routerReducer from './routerSlice';
import popoutReducer from './popoutSlice';
import addingTestFormReducer from './addingTestFormSlice';
import appConfigSlice from './appConfigSlice';
import launchParamsSlice from './launchParamsSlice';
import cooperativePassageSlice from './cooperativePassageSlice';

import logger from 'redux-logger';

const rootReducer = combineReducers({
  tests: testsReducer,
  test: testReducer,
  results: resultsReducer,
  result: resultReducer,
  user: userReducer,
  settings: settingsReducer,
  router: routerReducer,
  popout: popoutReducer,
  addingTestForm: addingTestFormReducer,
  appConfig: appConfigSlice,
  launchParams: launchParamsSlice,
  cooperativePassage: cooperativePassageSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch;

export default store;
