import { FC } from "react";
import { CollaborativeRequestForm } from "./CollaborativeRequestForm";
import { useDispatch, useSelector } from "react-redux";
import { getOutUserCommonData } from "../../../../../../api/VKWebApi";
import { MODAL } from "../../../../../../constants/Modal";
import { useRouter } from "../../../../../../hooks/useRouter";
import { updateSelectedUser } from "../../../../../../store/cooperativePassageSlice";
import { selectAppearance } from "../../../../../../store/settingsSlice/selectors";

const CollaborativeRequestFormContainer: FC = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const appearance = useSelector(selectAppearance);

    const handleRequestButton = async (linlToRofile: string) => {
        const user_id = Number(linlToRofile
        .replace('https','')
        .replace('http','')
        .replace('://','')
        .replace('/id','')
        .replace('vk.com','')
        .replace('/',''));

        if (!user_id || isNaN(user_id)) {
            alert('Вы ввели неверныую ссылку');
            return;
        }

        const data = await getOutUserCommonData(user_id);
        const userInfo = {
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            photo: data.photo_100
        } 

        dispatch(updateSelectedUser(userInfo));
        router.forward({ modal: MODAL.REQUEST_CONFIRMATION });
    }

    return (
        <CollaborativeRequestForm handleRequestButton={handleRequestButton} appearance={appearance}/>
    );
};

export default CollaborativeRequestFormContainer;
