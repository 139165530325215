import React from 'react';

import {
  Button,
  CardGrid,
  ContentCard,
  Div,
  Title,
} from '@vkontakte/vkui';
import { Icon24ReplyOutline } from '@vkontakte/icons';

import styles from './ClarificationDescriptionHandler.module.css';
import { DEFAULT_TITLE } from './constants';

export type RepostType = {
  message: string;
  attachments: string;
  successfulEvent?: {
    type: string;
    name: string;
  };
  failedEvent?: {
    type: string;
    name: string;
  };
};

export type ClarificationDescriptionHandlerType = {
  title?: string,
  factors: {
    subtitle?: string;
    name?: string;
    clarification?: string;
    description?: string;
  }[];
  repost?: RepostType;
  repostResult: (repost?: RepostType) => void;
  isDesktop: boolean;
};

export const ClarificationDescriptionHandler: React.FC<ClarificationDescriptionHandlerType> = ({
  title = DEFAULT_TITLE,
  factors,
  repost,
  repostResult,
  isDesktop,
}) => {
  return (
    <>
      <Div style={{ paddingBottom: repost ? 6 : 12 }}>
        <div className={styles.clarificationDescriptionTitle}>
          <Title level='3' weight='1'>
            {title}
          </Title>
          {Boolean(repost) && (
            <Button
              size='l'
              appearance='accent'
              mode='tertiary'
              after={<Icon24ReplyOutline />}
              onClick={() => repostResult(repost)}
            >
              {isDesktop && 'Поделиться'}
            </Button>
          )}
        </div>
      </Div>
      <CardGrid size='l'>
        {factors.map(({ subtitle, name, clarification, description }, index) => (
          <ContentCard
            key={index}
            subtitle={subtitle}
            header={name}
            text={clarification}
            caption={description}
            mode='outline'
          />
        ))}
      </CardGrid>
    </>
  );
};
