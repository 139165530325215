import { MouseEvent } from 'react';
import { connect } from 'react-redux';

import { 
  Icon28BillheadOutline, 
  Icon28UserSquareOutline, 
  Icon28SettingsOutline,
  Icon28Users
} from '@vkontakte/icons';

import { RootState } from '../../../store';
import { selectView } from '../../../store/routerSlice/routerSelectors';

import { useRouter } from '../../../hooks/useRouter';

import { VIEW, ViewType } from '../../../constants/View';

import { MobileNavigation, MobileNavigationRestType } from './MobileNavigation';

type EpicEventTarget = EventTarget & {
  dataset: {
    story: ViewType;
  }
};

export type EpicMouseEvent = MouseEvent<HTMLElement> & {
  currentTarget: EpicEventTarget
};

const MobileNavigationContainer: React.FC<MobileNavigationRestType> = (props) => {
  const router = useRouter();
  const setView = (view: ViewType) => router.forward({ view });

  const onViewChange = (event: EpicMouseEvent) => setView(event.currentTarget.dataset.story);

  return (
    <MobileNavigation
      options={[
        {
          value: VIEW.TESTS,
          text: 'Тесты',
          label: <Icon28BillheadOutline/>,
          action: onViewChange,
        },
        {
          value: VIEW.JOINT_PASSAGE,
          text: 'Совместное прохождение',
          label: <Icon28Users/>,
          action: onViewChange,
        },
        {
          value: VIEW.PROFILE,
          text: 'Профиль',
          label: <Icon28UserSquareOutline/>,
          action: onViewChange,
        },
        {
          value: VIEW.SETTINGS,
          text: 'Настройки',
          label: <Icon28SettingsOutline/>,
          action: onViewChange,
        },
      ]}
      {...props}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    activeView: selectView(state),
  };
}

export default connect(mapStateToProps)(MobileNavigationContainer);
