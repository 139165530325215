import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import testsApi from '../../api/TestsApi';
import passingsApi from '../../api/PassingsApi';

import { TestType } from '../../types/test.type';
import { PassingType } from '../../types/passing.type';
import { UserAnswerType } from '../../types/userAnswer.type';

import { updateTestFromArray } from '../testsSlice';
import { selectTestByShortName } from '../testsSlice/testsSelectors';

import { isError } from '../../utils/isError';
import { RootState } from '..';

type TestStateType = {
  data: TestType | null;
  loading: boolean;
  error: string | null;
};

export const fetchTest = createAsyncThunk<TestType | undefined, string, { rejectValue: string }>(
  'test/fetchTest',
  async function (shortName, { rejectWithValue, fulfillWithValue, getState, dispatch }) {
    try {
      const state = getState() as RootState;
      if (state.test.data) {
        dispatch(updateTestFromArray(state.test.data));
      }

      const test = selectTestByShortName(getState() as RootState, shortName);

      if (test?.questions) {
        return fulfillWithValue(test);
      }

      const testData: TestType = await testsApi.getTest(shortName);
      testData.questions?.sort((a, b) => a.position - b.position);

      const passingData: PassingType = await passingsApi.getPassing(shortName);

      console.log('-----------------------------------------');
      console.log(passingData);
      console.log('-----------------------------------------');

      testData.questions?.forEach((question, index) => {
        question.position = index;
        const userAnswer = passingData?.answers.find((answer) => answer.q_id === question.q_id);

        if (userAnswer) {
          const {
            state,
            time,
            items: user_answers,
          } = userAnswer;
          Object.assign(question, { state, time, user_answers: user_answers || [] });
        } else {
          Object.assign(question, { state: 'unanswered', user_answers: [] });
        }
      });

      const fullTest = {
        ...test,
        ...testData,
        is_passing_started: Boolean(passingData),
      };

      dispatch(updateTestFromArray(fullTest));
      
      return fullTest;
    }
    catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const testInitialState: TestStateType = {
  data: null,
  loading: false,
  error: null,
};

const testSlice = createSlice({
  name: 'test',
  initialState: testInitialState,
  reducers: {
    updateTest(state, action: PayloadAction<TestType>) {
      if (state.data) {
        Object.assign(state.data, action.payload);
      } else {
        state.data = action.payload;
      }
    },
    resetTest(state) {
      state.data = null;
    },
    resetQuestions(state) {
      if (!state.data) {
        return;
      }

      state.data.questions = state.data.questions?.map((question) => ({
        ...question,
        state: 'unanswered',
        user_answers: [],
      }));
    },
    resetPercents(state) {
      if (!state.data || !state.data.additional_info) {
        return;
      }

      state.data.additional_info.answered_questions_count = 0;
    },
    resetPassingStatus(state) {
      if (!state.data) {
        return;
      }

      state.data.is_passing_started = false;
    },
    updateUserAnswers(state, action: PayloadAction<UserAnswerType>) {
      const {
        q_id: questionId,
        items: user_answers,
        ...userAnswer
      } = action.payload;

      state.data?.questions?.forEach((question) => {
        if (question.q_id === questionId) {
          Object.assign(question, {
            ...userAnswer,
            user_answers,
          });
        }
      });
    },
    updateAnsweredQuestionsCount(state, action: PayloadAction<number>) {
      if (state.data?.additional_info) {
        state.data.additional_info.answered_questions_count = action.payload;
      }
    },
    acceptQuestion(state, action: PayloadAction<number>) {
      const questionId = action.payload;

      state.data?.questions?.forEach((question) => {
        if (question.q_id === questionId) {
          question.state = 'accepted';
        }
      });

      if (state.data?.is_passing_started) {
        state.data.is_passing_started = true;
      }
    },
    skipQuestion(state, action: PayloadAction<number>) {
      const questionId = action.payload;

      state.data?.questions?.forEach((question) => {
        if (question.q_id === questionId) {
          question.state = 'skipped';
        }
      });
    },
    startPassing(state) {
      if (state.data) {
        state.data.is_passing_started = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTest.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload;
        }
        state.loading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const {
  updateTest,
  resetTest,
  updateUserAnswers,
  updateAnsweredQuestionsCount,
  acceptQuestion,
  skipQuestion,
  resetQuestions,
  resetPassingStatus,
  resetPercents,
  startPassing,
} = testSlice.actions;

export default testSlice.reducer;
