import { FC } from "react";
import { FriendshipCodeProps } from "./types";
import { Button, Input } from "@vkontakte/vkui";

import styles from './FriendshipCode.module.css';

export const FriendshipCode: FC<FriendshipCodeProps> = ({
    userId,
    onFriendshipCodeChange,
    onCopyFriendshipCodeButtonClick,
    onSendRequestButtonClick,
    appearance
}) => {
    return (
        <div className={styles['container_' + appearance]}>
            <div>Ваш код дружбы</div>
            <div className={styles['codeContainer_' + appearance]}>
                <div className={styles.code}>{userId}</div>
                <Button onClick={onCopyFriendshipCodeButtonClick}>Копировать</Button>
            </div>
            <p>Введите код дружбы того, кому хотите отправить приглашение.</p>
            <Input onChange={(e) => onFriendshipCodeChange(Number(e.target.value))}placeholder="Введите код дружбы" type="text"/>
            <div className={styles.buttonContainer}>
                <Button onClick={onSendRequestButtonClick} className={styles.button}>Отправить приглашение</Button>
            </div>
        </div>
    );
};
