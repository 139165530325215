import { FC, useState } from "react";
import { UserItemContainerProps } from "./types";
import { UserItem } from "./UserItem";
import usersApi from "../../../../../../api/UsersApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserVKID } from "../../../../../../store/userSlice/userSelectors";
import { updateUser } from "../../../../../../store/userSlice";

const UserItemContianer: FC<UserItemContainerProps> = ({ userData }) => {
    const [visible, setVisible] = useState(true);
    const userId = useSelector(selectUserVKID);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const onAcceptClick = async () => {
        setVisible(false);
        await usersApi.addFrined(userData.vkId);
        console.log(userData.vkId);
        usersApi.deleteIncomingRequest(userId, userData.vkId);
        usersApi.deleteSubmittedRequest(userData.vkId, userId);

        const newIncominRequestsUserIds = user!.social?.incomingRequestsUsersIds.filter((id) => id !== userData.vkId) || [];
        const social = Object.assign({}, user?.social);
        social.incomingRequestsUsersIds = newIncominRequestsUserIds;
        social.friendsIds = [...social.friendsIds, userData.vkId];

        dispatch(updateUser({ social: social}));
    };

    const onDenyClick = () => {
        setVisible(false);
        usersApi.deleteIncomingRequest(userId, userData.vkId);
        usersApi.deleteSubmittedRequest(userData.vkId, userId);

        const newIncominRequestsUserIds = user!.social?.incomingRequestsUsersIds.filter((id) => id !== userData.vkId) || [];
        const social = Object.assign({}, user?.social);
        social.incomingRequestsUsersIds = newIncominRequestsUserIds;

        dispatch(updateUser({ social: social}));
    }

    return (
        <UserItem   
            userData={userData}
            onAcceptClick={onAcceptClick}
            onDenyClick={onDenyClick}
            visible={visible}
        />
    );
};

export default UserItemContianer;
