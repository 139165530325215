import { FC, useState } from "react";
import { FriendshipCodeConatienrProps } from "./types";
import { FriendshipCode } from "./FriendshipCode";
import { useDispatch, useSelector } from "react-redux";
import { selectUserVKID } from "../../../../../../store/userSlice/userSelectors";
import { getOutUserCommonData } from "../../../../../../api/VKWebApi";
import { MODAL } from "../../../../../../constants/Modal";
import { useRouter } from "../../../../../../hooks/useRouter";
import { updateSelectedUser } from "../../../../../../store/cooperativePassageSlice";
import { selectAppearance } from "../../../../../../store/settingsSlice/selectors";

const FriendshipCodeContainer: FC<FriendshipCodeConatienrProps> = () => {
    const userId = useSelector(selectUserVKID);
    const router = useRouter();
    const dispatch = useDispatch();
    const appearance = useSelector(selectAppearance);

    const [friendshipCode, setFriendshipCode] = useState<number>();

    const onFriendshipCodeChange = (value: number) => {
        setFriendshipCode(value);
    };

    const onCopyFriendshipCodeButtonClick = () => {
        if(navigator.clipboard) {
            navigator.clipboard.writeText(String(userId));
        }
    };

    const onSendRequestButtonClick = async() => {
        if (friendshipCode) {
            const data = await getOutUserCommonData(friendshipCode);
            const userInfo = {
                id: data.id,
                firstName: data.first_name,
                lastName: data.last_name,
                photo: data.photo_100
            } 

            dispatch(updateSelectedUser(userInfo));
            router.forward({ modal: MODAL.REQUEST_CONFIRMATION });
        }
    };

    return (
        <FriendshipCode 
            userId={userId} 
            onFriendshipCodeChange={onFriendshipCodeChange} 
            onCopyFriendshipCodeButtonClick={onCopyFriendshipCodeButtonClick}
            onSendRequestButtonClick={onSendRequestButtonClick}
            appearance={appearance}
        />
    );
};

export default FriendshipCodeContainer;
