import { FC } from "react";
import { AddFriendPanelContainerProps } from "./types";
import { AddFriendPanel } from "./AddFriendPanel";
import { useDispatch, useSelector } from "react-redux";
import { selectIsDesktop } from "../../../../store/settingsSlice/selectors";
import { useRouter } from "../../../../hooks/useRouter";
import { selectIsMenuOpen } from "../../../../store/cooperativePassageSlice/selectors";
import { updateIsMenuOpen } from "../../../../store/cooperativePassageSlice";

const AddFriendPanelContainer: FC<AddFriendPanelContainerProps> = ({ id }) => {
    const isDesktop = useSelector(selectIsDesktop);
    const router = useRouter();
    const isMenuOpen = useSelector(selectIsMenuOpen);
    const dispatch = useDispatch()

    const onBackClick = () => {
        router.back();
    }

     const openMenu = () => {
        dispatch(updateIsMenuOpen(true));
    }

    return (
        <AddFriendPanel
            id={id}
            isDesktop={isDesktop}
            onBackClick={onBackClick}
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
        />
    );
};

export default AddFriendPanelContainer;