import { RootState } from "..";

export const selectSelectedUser = (state: RootState) => state.cooperativePassage.selectedUser;

export const selectIsMenuOpen = (state: RootState) => state.cooperativePassage.isMenuOpen;

export const selectCurrentCooperativeTest = (state: RootState) => state.cooperativePassage.currentCooperativeTest;

export const selectUserFriendsInfo = (state: RootState) => state.cooperativePassage.friendsInfo;

export const selectCurrentResultType = (state: RootState) => state.cooperativePassage.currentResultType;
