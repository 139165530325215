import { FC, useState } from "react";
import { UserItemContainerProps } from "./types";
import { UserItem } from "./UserItem";
import { useDispatch, useSelector } from "react-redux";
import { selectUserVKID } from "../../../../../../store/userSlice/userSelectors";
import { temperamentData } from '../../constants';
import { useAppearance } from "@vkontakte/vkui";
import { useRouter } from "../../../../../../hooks/useRouter";
import { PANEL } from "../../../../../../constants/Panel";
import { updateCurrentResultType } from "../../../../../../store/cooperativePassageSlice";
import { selectResultByTestShortName } from "../../../../../../store/resultsSlice/resultsSelectors";
import store, { RootState } from "../../../../../../store";


const UserItemContianer: FC<UserItemContainerProps> = ({ userData, result }) => {
    const appearance = useAppearance();
    const router = useRouter();
    const dispatch = useDispatch();

    //@ts-ignore

    const userResult = useSelector((state: RootState) => selectResultByTestShortName(store.getState(), 'temperament'))?.result[0].factors[0].name.split(' ')[4];

    const getTemperamentData = () => {
        //@ts-ignore
        return temperamentData[result]
    }

    const onClick = () => {
        dispatch(updateCurrentResultType([userResult, result]))
        router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_RESULT });
    }

    return (
        <UserItem   
            userData={userData}  
            temperamentData={getTemperamentData()}
            appearance={appearance}
            onClick={onClick}
        />
    );
};

export default UserItemContianer;
