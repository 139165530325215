import { FC, useEffect, useState } from "react";
import { TestCardContainerProps } from "./types";
import { TestCard } from "./TestCard";
import usersApi from "../../../../../../api/UsersApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUserFriendsIds } from "../../../../../../store/userSlice/userSelectors";
import { useRouter } from "../../../../../../hooks/useRouter";
import { PANEL } from "../../../../../../constants/Panel";
import { setCurrentCooperativeTest } from "../../../../../../store/cooperativePassageSlice";
import { useAppearance } from "@vkontakte/vkui";
import { selectIsDesktop } from "../../../../../../store/settingsSlice/selectors";
import e from "express";
import { selectResultByTestShortName } from "../../../../../../store/resultsSlice/resultsSelectors";
import store, { RootState } from "../../../../../../store";
import { MODAL } from "../../../../../../constants/Modal";

const TestCardContainer: FC<TestCardContainerProps> = ({ test }) => {
    const friends = useSelector(selectUserFriendsIds);
    const [friendsThatPassedTest, setFriendsThatPassedTest] = useState();
    const [loading, setLoading] = useState(true);
    const router = useRouter();
    const dispatch = useDispatch();
    const appearance = useAppearance();
    const isDesktop = useSelector(selectIsDesktop);
    const result = useSelector((state: RootState) => selectResultByTestShortName(store.getState(), test.short_name));

    useEffect(() => {
        const getFriendsThatPassedTest = async() => {
            const data = await usersApi.getFriendsThatPassedTest(test.short_name);
            setFriendsThatPassedTest(data);
            setLoading(false);
        };

        getFriendsThatPassedTest();
    }, []);

    const onClick = () => {
        dispatch(setCurrentCooperativeTest(test));
        if (result) {
            router.forward({ panel: PANEL.COOPERATIVE_PASSAGE_COOPERATIVE_TEST});
        }
        else {
            router.forward({ modal: MODAL.NOT_PASSED});
        }
    };

    return (
        <TestCard
            test={test}
            friends={friends}
            friendsThatPassedTest={friendsThatPassedTest}
            loading={loading}
            onClick={onClick}
            appearance={appearance}
            deviceType={isDesktop ? 'desktop' : 'mobile'}
        />
    );
};

export default TestCardContainer;
