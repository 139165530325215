import { Avatar, Button, ModalPage, ModalPageHeader, Spacing } from "@vkontakte/vkui";
import { RequestConfirmationModalProps } from "./types";
import { FC } from "react";
import styles from './RequestConfirmationModal.module.css';

export const RequestConfirmationModal: FC<RequestConfirmationModalProps> = ({
id,
userInfo,
onCancelClick,
onAcceptClick,
status
}) => {

    return (
        <ModalPage id={id}>
            <ModalPageHeader>Подтверждение запроса</ModalPageHeader>
            { status === 'default' &&
                <div className={styles.container}>
                    <h3>Вы уверены, что хотите отправить запрос этому пользователю?</h3>
                    <div className={styles.userContainer}>
                        <Avatar size={96} src={userInfo.photo}/>
                        <p className={styles.text}>{userInfo.firstName} {userInfo.lastName}</p>
                    </div>
                    <Spacing size={32}/>
                    <div className={styles.userContainer}>
                        <Button onClick={onCancelClick}>Нет</Button>
                        <Button onClick={onAcceptClick}>Да</Button>
                    </div>
                </div>
            }
            { status === 'hasSubmittedRequest' &&
                <div className={styles.container}>
                    <p>Вы уже отправили запрос этому пользователю</p>
                    <div className={styles.userContainer}>
                        <Button onClick={onCancelClick}>Хорошо</Button>
                    </div>
                </div>
            }
            { status === 'isAlreadyFriend' &&
                <div className={styles.container}>
                    <p>Этот пользователь уже является вашим другом</p>
                    <div className={styles.userContainer}>
                        <Button onClick={onCancelClick}>Хорошо</Button>
                    </div>
                </div>
            }
            { status === 'selfRequest' &&
                <div className={styles.container}>
                    <p>Вы не можете отправить запрос самому себе</p>
                    <div className={styles.userContainer}>
                        <Button onClick={onCancelClick}>Хорошо</Button>
                    </div>
                </div>
            }
            { status === 'userDoesntExists' &&
                <div className={styles.container}>
                    <p>Данный пользователь не является пользователем нашего приложения</p>
                    <p>Для отпраки заявки другому пользвоателю необходимо запустить наше приложение</p>
                    <div className={styles.userContainer}>
                        <Button onClick={onCancelClick}>Хорошо</Button>
                    </div>
                </div>
            }
        </ModalPage>
    );
};
