import { FC } from "react";
import { AddFriendPanelProps } from "./types";
import { Group, Panel, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";
import { DesktopNavigation } from "../../../../components/navigation/DesktopNavigation";
import { NavigationMenu } from "../../components/NavigationMenu";
import { NAVIGATION } from "../../../../constants/Navigation";
import { CollaborativeRequestForm } from "./components/CollaborativeRequestForm";

import styles from './AddFriendPanel.module.css';
import { FriendshipCode } from "./components/FriendshipCode";
import { FriendList } from "./components/FriendList";
import { MobileNavigationMenu } from "../../components/MobileNavigationMenu";
import { Icon28Menu } from "@vkontakte/icons";

export const AddFriendPanel: FC<AddFriendPanelProps> = ({ 
    id,
    isDesktop,
    onBackClick,
    isMenuOpen,
    openMenu,
}) => {
    return (
        <Panel>
            <PanelHeader onClick={onBackClick} before={<PanelHeaderBack/>}>Совместные прохождения: добавить друга</PanelHeader>
            <Group>
                {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}         
                <div className={isDesktop && isMenuOpen ? styles.container_open: ''}>
                    {isDesktop ? <NavigationMenu/> : isMenuOpen ? <MobileNavigationMenu/> : <Icon28Menu onClick={openMenu} width={50} height={50}/>}
                    {(isDesktop || (!isDesktop && !isMenuOpen)) &&
                        <div className={styles.panelContainer}>
                            <FriendshipCode/>
                            <FriendList/>
                            <CollaborativeRequestForm/>
                        </div>
                    }
                </div>
            </Group>
        </Panel>
    );
};
