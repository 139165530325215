import { FC } from "react";
import { SubmittedRequestsPanelProps } from "./types";
import { Group, Panel, PanelHeader, PanelHeaderBack, Spinner, Title } from "@vkontakte/vkui";
import { DesktopNavigation } from "../../../../components/navigation/DesktopNavigation";
import { NAVIGATION } from "../../../../constants/Navigation";

import styles from './SubmittedRequestsPanel.module.css';
import { UserItem } from "./components/UserItem";
import { NavigationMenu } from "../../components/NavigationMenu";
import { MobileNavigationMenu } from "../../components/MobileNavigationMenu";
import { Icon28Menu } from "@vkontakte/icons";

export const SubmittedRequestsPanel: FC<SubmittedRequestsPanelProps> = ({
    id,
    isDesktop,
    onBackClick,
    usersData,
    loading,
    appearance,
    isMenuOpen,
    openMenu
 }) => {
    return (
        <Panel id={id}>
            <PanelHeader before={<PanelHeaderBack onClick={onBackClick}/>}>Совместные прохождения: отправленные заявки</PanelHeader>
            <Group>
                {isDesktop && <DesktopNavigation id={NAVIGATION.DESKTOP} />}         
                <div className={isDesktop && isMenuOpen ? styles.container_open: ''}>
                    {isDesktop ? <NavigationMenu/> : isMenuOpen ? <MobileNavigationMenu/> : <Icon28Menu onClick={openMenu} width={50} height={50}/>}
                    {(isDesktop || (!isDesktop && !isMenuOpen)) &&
                        <div className={styles.panelContainer}>
                            <div className={styles.titleContainer}>
                                <Title>Отправленные заяки</Title>
                            </div>
                            {loading && 
                                <Spinner/>
                            }
                            {!loading && usersData.length === 0 &&
                                <div className={styles.titleContainer}>
                                    <Title level="2">У вас нет отправленных заявок</Title>
                                </div>
                            }
                            {usersData && 
                                usersData.map((userData, index) => {
                                    return (
                                        <div className={index % 2 === 0 ? styles['backgroundEven_' + appearance] : styles['backgroundOdd_' + appearance]}>
                                            <UserItem userData={userData}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </Group>
        </Panel>
    );
};
